import { createClient } from "@supabase/supabase-js";
import { storage } from "@/storage";

const supabaseUrl = "https://ktvcyirvkpgdhazlojti.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt0dmN5aXJ2a3BnZGhhemxvanRpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQ2MDM5ODAsImV4cCI6MTk5MDE3OTk4MH0.2f4QrPACDHgsgC0ij7o6nlGrHc9Z894T4KEOciRcKBg";

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: storage as any,
    storageKey: 'supabase_auth',
  },
});
