<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> Data Explorer </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              Filter options
              <ion-item lines="full">
                <ion-grid>
                  <ion-row class="ion-justify-content-center">
                    <ion-col>
                      <ion-item>
                        <ion-label position="stacked">Filter by App</ion-label>
                        <ion-select v-model="currentAppId">
                          <ion-select-option
                            v-for="app in apps"
                            :key="app.id"
                            :value="app.id"
                          >
                            {{ app.name }}
                          </ion-select-option>
                        </ion-select>
                      </ion-item>
                    </ion-col>
                    <ion-col>
                      <ion-item lines="full">
                        <ion-label position="stacked">Filter by User</ion-label>
                        <ion-select v-model="currentUserId">
                          <ion-select-option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ user.email }}
                          </ion-select-option>
                        </ion-select>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-justify-content-center">
                    <ion-col>
                      <ion-item lines="full">
                        <ion-label position="stacked"
                          >Filter by Date (from)</ion-label
                        >
                      </ion-item>
                      <ion-datetime-button
                        datetime="date_start"
                        v-model="currentDateFrom"
                      ></ion-datetime-button>
                      <ion-modal :keep-contents-mounted="true">
                        <ion-datetime
                          id="date_start"
                          ref="datetime_from"
                          presentation="date"
                          v-model="currentDateFrom"
                          :max="currentDateTo"
                          :show-default-buttons="true"
                        ></ion-datetime>
                      </ion-modal>
                    </ion-col>
                    <ion-col>
                      <ion-item>
                        <ion-label position="stacked"
                          >Filter by Date (to)</ion-label
                        >
                      </ion-item>
                      <ion-datetime-button
                        datetime="date_end"
                      ></ion-datetime-button>
                      <ion-modal :keep-contents-mounted="true">
                        <ion-datetime
                          id="date_end"
                          ref="datetime_to"
                          presentation="date"
                          v-model="currentDateTo"
                          :min="currentDateFrom"
                          :show-default-buttons="true"
                        ></ion-datetime>
                      </ion-modal>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
              <ion-item slot="start">
                <ion-button slot="start" @click="filterByAllCriteria"
                  >Load Data
                  {{
                    expectedResults > 0 ? `(${expectedResults})` : ""
                  }}</ion-button
                >
                <ion-button slot="start" color="danger" @click="deleteFilter"
                  >Delete filter</ion-button
                >
              </ion-item>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-title> Data Files </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <template v-if="dataFiles.length == 0">
                No data files loaded.
              </template>
              <template v-else>
                <ion-item lines="full">
                  <ion-button
                    class="fileButton"
                    @click="exportFile()"
                    color="primary"
                    fill="outline"
                  >
                    Export
                  </ion-button>
                </ion-item>
                <ion-item lines="full">
                  <ion-chip color="light" slot="start">
                    <ion-checkbox
                      id="mainSelection"
                      :checked="isMainChecked"
                      :indeterminate="isMainIndeterminate"
                      @ionChange="setAllOnOff($event)"
                    />
                  </ion-chip>
                  <ion-button slot="start" @click="invertSelection">
                    Invert
                  </ion-button>
                  <ion-text
                    :color="
                      selectedFiles > 0
                        ? 'primary'
                        : isDarkMode()
                        ? '#ffffff'
                        : '#000000'
                    "
                  >
                    {{ checkedUUIDs.length }} of {{ dataFiles.length }} selected
                  </ion-text>
                </ion-item>
                <ion-list>
                  <file-list-entry
                    v-for="file in dataFiles"
                    :key="file.uuid"
                    :appId="file.appId"
                    :userId="file.userId"
                    :uuid="file.uuid"
                    :checked="checkedUUIDs.includes(file.uuid)"
                    @selected="onFileSelected"
                    @unselected="onFileUnselected"
                    :apps="apps"
                    :users="users"
                    :filename="file.filename"
                    :createdAt="file.createdAt"
                    :data="file"
                  />
                  <ion-item lines="none" />
                </ion-list>
              </template>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonGrid,
  IonList,
  IonRow,
  IonCol,
  IonContent,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  alertController,
  loadingController,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonChip,
  IonText,
} from "@ionic/vue";

import { ref, onMounted, computed, watch } from "vue";
import { supabase } from "@/supabaseClient";
import { getAllUsers } from "@/supabase/user";
import { isDarkMode } from "@/utils/index";
import FileListEntry from "@/components/FileListEntry.vue";

import { writeDataFile } from "@/utils/filesystem";

const dataFiles = ref([]);
let status = undefined;
const selectedFiles = ref(0);
const apps = ref([]);
const users = ref([]);
const currentAppId = ref(undefined);
const currentUserId = ref(undefined);
const currentDateFrom = ref(undefined);
const currentDateTo = ref(undefined);
let loadData = undefined;
const checkedUUIDs = ref([]);
const isMainChecked = ref(false);
const isFilterActive = ref(false);
const datetime_from = ref();
const datetime_to = ref();
const reset_from = () => datetime_from.value.$el.reset();
const reset_to = () => datetime_to.value.$el.reset();
let firstDate = '';
let lastDate = '';

const expectedResults = ref(0);

watch([currentAppId, currentUserId, currentDateFrom, currentDateTo], () => {
  preRunFilter();
  // isFilterActive.value = true;
});

const isMainIndeterminate = computed(() => {
  return (
    checkedUUIDs.value.length > 0 &&
    checkedUUIDs.value.length !== dataFiles.value.length
  );
});

onMounted(async () => {
  getApps();
  updateUsers();
  // load initial dates
  const query = createSelectQuery("filestring->>createdAt", {
    order: { key: "filestring->>createdAt", ascending: true },
    limit: 1,
  });
  const res = await query;
  if (!res.error) {
    firstDate = new Date(res.data[0].createdAt).toISOString();
    console.log(firstDate);
    currentDateFrom.value = firstDate ;
  }
  const query2 = createSelectQuery("filestring->>createdAt", {
    order: { key: "filestring->>createdAt", ascending: false },
    limit: 1,
  });
  const res2 = await query2;
  if (!res2.error) {
    lastDate = new Date(res2.data[0].createdAt).toISOString();
    console.log(lastDate);
    currentDateTo.value = lastDate;
  }
});

function deleteFilter() {
  currentAppId.value = undefined;
  currentUserId.value = undefined;
  currentDateFrom.value = firstDate;
  currentDateTo.value = lastDate;
  isFilterActive.value = false;
  reset_from();
  reset_to();
}

// function appIdToName(appId) {
//   const app = apps.value.find(app => app.id === appId);
//   return app.name;
// }

// function userIdToName(userId) {
//   const user = users.value.find(user => user.id === userId);
//   return user.email;
// }

function onFileSelected(fileUUID) {
  selectFile(fileUUID);
  if (!checkedUUIDs.value.includes(fileUUID)) {
    checkedUUIDs.value.push(fileUUID);
  }
}

function onFileUnselected(fileUUID) {
  unselectFile(fileUUID);
}

function selectFile(fileUUID) {
  if (!checkedUUIDs.value.includes(fileUUID)) {
    checkedUUIDs.value.push(fileUUID);
  }
}

function unselectFile(fileUUID) {
  if (checkedUUIDs.value.includes(fileUUID)) {
    checkedUUIDs.value.splice(checkedUUIDs.value.indexOf(fileUUID), 1);
    isMainChecked.value = false;
  }
}

async function createLoadController(loadingMessage) {
  loadData = await loadingController.create({
    message: loadingMessage,
  });
}

async function getApps() {
  const res = await supabase.from("apps").select().order("name");

  console.log("apps", res.data);

  apps.value = res.data;
}

async function updateUsers() {
  users.value = await getAllUsers();
}

function setDataFiles(dataF) {
  if (!dataF) {
    dataFiles.value = [];
    checkedUUIDs.value = [];
    return;
  }
  dataFiles.value = dataF;
  checkedUUIDs.value = [];
}

async function createSelectQuery(fields, options) {
  if (!fields) {
    fields = "*";
  }
  let query = supabase.from("datafiles").select(fields, options);

  if (currentAppId.value) {
    query = query.eq("filestring->>appId", currentAppId.value);
    isFilterActive.value = true;
  }
  if (currentUserId.value) {
    query = query.eq("filestring->>userId", currentUserId.value);
    isFilterActive.value = true;
  }
  if (currentDateFrom.value) {
    query = query.gte(
      "filestring->>createdAt",
      getDate(currentDateFrom.value, false)
    );
    isFilterActive.value = true;
  }
  if (currentDateTo.value) {
    query = query.lte(
      "filestring->>createdAt",
      getDate(currentDateTo.value, true)
    );
    isFilterActive.value = true;
  }
  if (Object.hasOwn(options, "order")) {
    query = query.order(options.order.key, options.order);
  }
  if (Object.hasOwn(options, "limit")) {
    query = query.limit(options.limit);
  }
  return query;
}

async function filterByAllCriteria() {
  await createLoadController("Please wait...");
  
  const query = createSelectQuery(
    "filestring->filename, filestring->userId, filestring->createdAt, filestring->appId, filestring->uuid",
    { order: { key: "created_at", ascending: false } }
  );

  if (isFilterActive.value) {
    loadData.present();
    const res = await query;
    if (!res.error) {
      setDataFiles(res.data);
    }
    loadData.dismiss();
  }
}

function getDate(timeStamp, isEndDate) {
  const date = new Date(timeStamp);
  const currentMonth = getRealMonthDate(date.getMonth() + 1);
  const currentDate = isEndDate
    ? getRealMonthDate(date.getDate() + 1)
    : getRealMonthDate(date.getDate());
  const dateFormat =
    date.getFullYear() + "-" + currentMonth + "-" + currentDate;

  return dateFormat;
}

function getRealMonthDate(monthOrDate) {
  return monthOrDate < 10 ? "0" + monthOrDate : monthOrDate;
}

function setAllOnOff($event) {
  console.log("checked selectAll:", $event);
  console.log("checked selectAll:", $event.srcElement.id);
  if ($event.detail.checked == true) {
    checkedUUIDs.value = dataFiles.value.map((file) => file.uuid);
  } else {
    checkedUUIDs.value = [];
  }
  console.log("checkedUUIDs", checkedUUIDs.value);
}

async function getStatus(statusMessage) {
  status = await alertController.create({
    message: statusMessage,
    buttons: ["OK"],
  });

  return status;
}

async function exportFile() {
  if (checkedUUIDs.value.length === 0) {
    await getStatus(
      "No data files are select. Please select at least one item."
    );
    await status.present();
    return;
  } else {
    const cleanDataFilesForDownload = [];
    const propertiesToBeRemoved = ["checked", "isUp", "isExported"];
    const chunkSize = 32;
    let UuidsToDownload = JSON.parse(JSON.stringify(checkedUUIDs.value));
    const dataFiles = [];
    createLoadController("Download started, please wait...");
    while (UuidsToDownload.length > 0) {
      const chunk = UuidsToDownload.splice(0, chunkSize);
      const res = await supabase
        .from("datafiles")
        .select("filestring")
        .in("id", chunk)
        .order("created_at", { ascending: false });

      if (res.error) {
        console.log("error", res.error);
        loadData.dismiss();
      }
      dataFiles.push(...res.data);

      UuidsToDownload = UuidsToDownload.filter((uuid) => !chunk.includes(uuid));
    }

    for (const item of dataFiles) {
      for (const property of propertiesToBeRemoved) {
        delete item[property];
      }
      cleanDataFilesForDownload.push(item);
    }

    const text = JSON.stringify(cleanDataFilesForDownload);
    const filename = getCurrentDateTime() + "_dataFiles.json";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/json;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // if success
    for (const item of dataFiles) {
      console.log(item);
      const fileData = item.filestring;
      console.log("fileData", item.filestring);
      await writeDataFile(fileData.filename, JSON.stringify(fileData));
    }
    loadData.dismiss();
  }
}

function invertSelection() {
  console.log("invertSelection");
  console.log("checkedUUIDs", checkedUUIDs.value);
  console.log("dataFiles.value", dataFiles.value);
  const newCheckedUUIDs = dataFiles.value
    .filter((file) => {
      return !checkedUUIDs.value.includes(file.uuid);
    })
    .map((file) => {
      return file.uuid;
    });
  console.log("newCheckedUUIDs", newCheckedUUIDs);
  checkedUUIDs.value = newCheckedUUIDs;
  return;
}

function getCurrentDateTime() {
  const date = new Date();
  const currentDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  const currentTime =
    date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();

  return currentDate + "_" + currentTime;
}

async function preRunFilter() {
  const query = createSelectQuery("id", { count: "exact", head: true });
  const res = await query;
  console.log("res", res);
  if (!res.error) {
    expectedResults.value = res.count;
  } else {
    expectedResults.value = 0;
  }
}
</script>
<style scoped>
.fileButton {
  margin-right: 1em;
  margin-bottom: 1em;
}
</style>
