<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>{{ test }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p>{{ testOption }}</p>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardContent 
} from "@ionic/vue";

import { onMounted, onBeforeUnmount, ref } from "vue";
import { randomNumberBetween, randomInt } from "@/utils/math";

const props = defineProps({
  testOption: { type: String },
  bar: Number,
});

const test = ref("Test");
const k = randomInt(16);
let i = 0;
console.log("Creating interval");

const testInterval = setInterval(() => {
  console.log(k, "This is a test", i);
  test.value = "Test, " + k + ": " + i;
  i++;
}, randomNumberBetween(5000, 3000));

onMounted(() => {
  console.log("Component was mounted", k);
  console.log(props);
});

onBeforeUnmount(() => {
  clearInterval(testInterval);
  console.log("component was unmounted.", k);
});
</script>

<style></style>
