<template>
  <ion-accordion-group>
    <ion-accordion>
      <ion-item slot="header" lines="inset">
        <ion-item lines="none" class="ion-no-padding">
          <ion-button fill="clear" @click="$emit('removeMe')">
            <ion-icon class="bi bi-trash"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item lines="none" class="ion-no-padding">
          <ion-label position="floating">Section name</ion-label>
          <ion-input
            placeholder="Another section"
            :value="modelValue.name"
            @input="
              $emit('update:modelValue', {
                ...modelValue,
                name: $event.target.value,
              })
            "
          >
          </ion-input>
        </ion-item>
      </ion-item>

      <ion-list slot="content">
        <ion-item>
          {{ JSON.stringify(modelValue, null, 1) }}
        </ion-item>
        <ion-item lines="inset">
          <ion-label position="floating">Icon</ion-label>
          <ion-input
            placeholder="info"
            :value="modelValue.icon"
            @input="
              $emit('update:modelValue', {
                ...modelValue,
                icon: $event.target.value,
              })
            "
          >
          </ion-input>
        </ion-item>
        <ion-item
          v-for="(moduleDescription, moduleIndex) in modelValue.modules || []"
          :key="moduleIndex"
        >
          <module-edit
            :modelValue="moduleDescription"
            @update:modelValue="updateModule(moduleIndex, $event)"
            @removeMe="removeModule(moduleIndex)"
          ></module-edit>
        </ion-item>
        <ion-item button @click="addEmptyModule()">
          <ion-text color="primary">Add module</ion-text> 
          <ion-icon 
            slot="end" 
            color="primary" 
            class="bi bi-plus-circle" 
          />
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import {
  IonAccordion,
  IonAccordionGroup,
  IonList,
  IonText
} from "@ionic/vue";
import { computed, ref } from "vue";
import ModuleEdit from "@/components/ModuleEdit.vue";

const emit = defineEmits(["update:modelValue", "removeMe"]);

const sectionName = ref("");
const sectionIcon = ref("");
const modules = ref([]);

const props = defineProps({
  modelValue: {
    type: Object,
    default() {
      return {
        name: undefined,
        icon: undefined,
        modules: [],
      };
    },
  },
});
// eslint-disable-next-line
const currentValue = computed(() => {
  return {
    name: sectionName.value,
    icon: sectionIcon.value,
    modules: modules.value,
  };
});

function addEmptyModule() {
  emit("update:modelValue", {
    ...props.modelValue,
    modules: [...props.modelValue.modules, {}],
  });
}
function removeModule(index) {
  const temp = props.modelValue.modules;
  temp.splice(index, 1);
  emit("update:modelValue", {
    ...props.modelValue,
    modules: temp,
  });
}
function updateModule(index, newModule) {
  const temp = props.modelValue.modules;
  temp[index] = newModule;
  emit("update:modelValue", {
    ...props.modelValue,
    modules: temp,
  });
}
</script>

<style></style>
