
import { BleClient } from "@capacitor-community/bluetooth-le";

import bleUtils from "./bleUtils";


export async function requestDevice( opts = {} ) {
  return await BleClient.requestDevice(opts);
}

export function BleDevice( deviceId, opts = {} ) {
  const _opts = {
    debug: false,
    ...opts
  }
  const _id = deviceId;
  const _subscriptions = [];

  const connect = async () => {
    try {
      await BleClient.connect(_id);
      return true;
    } catch (error) {
      console.error( "connect", _id, error );
      return false;
    }
  }
  const getServices = async () => {
    try {
      const services = await BleClient.getServices( _id );
      if ( _opts.debug ) {
        console.log( "getServices", _id, services );
      }
      return services;
    } catch (error) {
      console.error( "getServices", _id, error );
    }
  }
  const read = async ( service, characteristic ) => {
    if ( _opts.debug ) {
      console.log( "read", _id, service, characteristic );
    }
    try {
      const value = await BleClient.read( _id, service, characteristic );
      if ( _opts.debug ) {
        console.log( "read", _id, service, characteristic, value );
      }
      return value;
    } catch (error) {
      console.log( "read", _id, service, characteristic, error );
    }
  }
  const readDataType = async ( service, characteristic, datatype ) => {
    if ( _opts.debug ) {
      console.log( "readDataType", _id, service, characteristic, datatype );
    }
    const value = await read( service, characteristic );
    if ( value ) {
      const data = bleUtils.toDataType( value, datatype );
      if ( _opts.debug ) {
        console.log( "readDataType", _id, service, characteristic, datatype, data );
      }
      return data;
    }
    throw new Error( "readDataType", _id, service, characteristic, datatype, "no value" );
  }
  const readString = async ( service, characteristic ) => {
    return await readDataType( service, characteristic, "string" );
  }

  const write = async ( service, characteristic, value ) => {
    if ( _opts.debug ) {
      console.log( "write", _id, service, characteristic, value );
    }
    try {
      await BleClient.write( _id, service, characteristic, value );
    } catch (error) {
      console.log( "write", _id, service, characteristic, value, error );
    }
  }

  const writeDataType = async ( service, characteristic, datatype, value ) => {
    if ( _opts.debug ) {
      console.log( "writeDataType", _id, service, characteristic, datatype, value );
    }
    if ( datatype === "string" ) {
      value = new DataView( new TextEncoder().encode( value ).buffer );
    } else if ( datatype === "uint8" ) {
      value = new DataView( new Uint8Array( [ value ] ).buffer );
    } else if ( datatype === "uint16" ) {
      value = new DataView( new Uint16Array( [ value ] ).buffer );
    } else if ( datatype === "uint32" ) {
      value = new DataView( new Uint32Array( [ value ] ).buffer );
    } else {
      throw new Error( "writeDataType", _id, service, characteristic, datatype, value, "unknown datatype" );
    }

    await write( service, characteristic, value );
  }
  const writeString = async ( service, characteristic, value ) => {
    if ( _opts.debug ) {
      console.log( "writeString", _id, service, characteristic, 
      value );
    }
    await writeDataType( service, characteristic, "string", value );
  }

  const subscribe = async ( service, characteristic, callback ) => {
    if ( _opts.debug ) {
      console.log( "subscribe", _id, service, characteristic );
    }
    try {
      await BleClient.startNotifications( _id, service, characteristic, callback );
      _subscriptions.push( { service, characteristic, callback } );
    } catch (error) {
      console.log( "subscribe", _id, service, characteristic, error );
    }
  }
  const subscribeDataType = async ( service, characteristic, datatype, callback ) => {
    if ( _opts.debug ) {
      console.log( "subscribeDataType", _id, service, characteristic, datatype );
    }
    const cb = ( value ) => {
      const data = bleUtils.toDataType( value, datatype );
      if ( _opts.debug ) {
        console.log( "subscribeDataType", _id, service, characteristic, datatype, data );
      }
      callback( data );
    }
    await subscribe( service, characteristic, cb );
  }
  const subscribeUint8 = async ( service, characteristic, callback ) => {
    return await subscribeDataType( service, characteristic, "uint8", callback );
  }

  const subscribeJson = async ( service, characteristic, callback ) => {
    if ( _opts.debug ) {
      console.log( "subscribeJson", _id, service, characteristic );
    }
    let incomingDataStringBuffer = "";
    const cb = ( value ) => {
      // Get the string from the buffer
      const string = bleUtils.toString( value );
      console.log( 'received data', string );
      // Append it to the incoming data
      incomingDataStringBuffer += string;
      // Validate that the string buffer has a valida start of a JSON string
      if ( !incomingDataStringBuffer.startsWith( "{"  ) && !incomingDataStringBuffer.startsWith( "[" ) ) {
        console.warn( 'invalid start of data, discarding data.', incomingDataStringBuffer);
        incomingDataStringBuffer = "";
        return;
      }
      // Check if the string buffer is valid JSON,
      try {
        console.log( 'parsing data', incomingDataStringBuffer );
        const jsonData = JSON.parse( incomingDataStringBuffer );
        if ( _opts.debug ) {
          console.log( "subscribeJson", _id, service, characteristic, jsonData );
        }
        // If valid JSON, reset the buffer for incoming data
        incomingDataStringBuffer = "";
        // and call the external callback with the JSON object.
        callback( jsonData );
      } catch (error) {
        // If not valid JSON, return
        return;
      }
    }
    await subscribe( service, characteristic, cb );
  }

  const unsubscribeAll = async () => {
    if ( _opts.debug ) {
      console.log( "unsubscribeAll", _id );
    }
    for ( const sub of _subscriptions ) {
      await BleClient.stopNotifications( _id, sub.service, sub.characteristic );
    }
    _subscriptions.length = 0;
  }

  const disconnect = async () => {
    await unsubscribeAll();
    await BleClient.disconnect( _id );
  }
  return {
    connect,
    disconnect,
    getServices,
    read,
    readDataType,
    readString,
    write,
    writeDataType,
    writeString,
    subscribe,
    subscribeDataType,
    subscribeUint8,
    subscribeJson,
  };


}

