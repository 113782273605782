<template>
  <ion-button
    :disabled="isDisabled"
    @click="emitClick"
    :expand="expand"
    :fill="fill"
    :shape="shape"
    :color="color"
  >
    {{ label }} &nbsp;
    <ion-spinner v-if="isProcessing" slot="end" name="circles"></ion-spinner>
    <span v-if="isDone">✔</span>
  </ion-button>
</template>

<script setup>
import { ref, watch } from "vue";
import { IonSpinner, IonButton } from "@ionic/vue";

const props = defineProps({
  label: {
    type: String,
    default: "Submit",
  },
  processing: {
    type: Boolean,
    default: false,
  },
  done: {
    type: Boolean,
    default: false,
  },
  resetTime: {
    type: Number,
    default: 1000,
  },
  color: {
    type: String,
    default: "primary",
  },
  fill: {
    type: String,
    default: "solid",
  },
  expand: {
    type: String,
    default: "block",
  },
  shape: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

const isDone = ref(false);
const isDisabled = ref(false);
const isProcessing = ref(false);

function emitClick(event) {
  emit("click", event);
}

watch(
  () => props.done,
  (newValue) => {
    console.log("done changed", newValue);
    isDone.value = newValue;
    if (newValue) {
      setTimeout(() => {
        isDone.value = false;
        isDisabled.value = false;
      }, props.resetTime);
    } else {
      isDone.value = false;
    }
  }
);
watch(
  () => props.processing,
  (newValue) => {
    console.log("processing changed", newValue);
    if (newValue) {
      // when set to true
      isDisabled.value = true;
      isProcessing.value = true;
    } else {
      // when set to false
      isProcessing.value = false;
    }
  }
);
</script>

<style></style>
