import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate"

const pinia = createPinia();

pinia.use(
  createPersistedState({
    storage: localStorage,
    beforeRestore: async (ctx) => {
      console.log(`about to restore '${ctx.store.$id}'`);
      console.log( 'from', localStorage );
    },
    afterRestore: (ctx) => {
      console.log(`just restored '${ctx.store.$id}'`);
      console.log( ctx.store);
    },
  })
);
export { pinia };
