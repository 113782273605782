<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> Record </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-progress-bar
        v-if="progressDisplay === 'bar'"
        :value="dataStore.length / limit"
      ></ion-progress-bar>
      <div
        v-if="progressDisplay === 'icon'"
        class="scanProgressIcons"
        :style="{ fontSize: iconFontSize / limit + 'vw' }"
      >
        <div v-for="(n, i) in limit" :key="i" class="scanProgressIcon">
          <div
            v-if="i < dataStore.length"
            class="bi bi-circle-fill scan-done"
          ></div>
          <div
            v-if="i == dataStore.length"
            class="bi bi-circle-fill scan-in-progress"
          ></div>
          <div
            v-if="i > dataStore.length"
            class="bi bi-circle scan-pending"
          ></div>
        </div>
      </div>
      <p>Frames: {{ dataStore.length }}</p>
      <p>last: {{ lastFilename }}</p>
    </ion-card-content>
    <ion-button fill="clear" @click="save">Save</ion-button>
    <ion-button fill="clear" @click="clearDataStore">Clear</ion-button>
    <ion-button fill="clear" @click="deleteLastFrame">Delete</ion-button>
  </ion-card>
</template>

<script setup>
import { 
  ref, 
  unref,
  onMounted, 
  onBeforeUnmount 
} from "vue";

import { IonProgressBar } from "@ionic/vue";
import { events } from "@/utils/events";
import { writeDataFile } from "@/utils/filesystem";
import { v4 as uuid4 } from "uuid";
import { useAuthStore } from "@/store/auth";
import { useAppStateStore } from "@/store/app";
import { useMetaStore } from "@/store/meta";
import { getActivePinia } from "pinia";

const props = defineProps({
  strategy: {
    type: String,
    default: "last",
    choice: ["last", "sequence"],
  },
  limit: {
    type: Number,
    default: 0,
  },
  progressDisplay: {
    type: String,
    default: "none",
    choice: ["none", "bar", "icon"],
  },
  autoSaveStrategy: {
    type: String,
    default: "none",
    choice: ["none", "onFull", "onOverflow"],
  },
  dataMessage: {
    type: String,
    default: "data",
  },
  iconFontSize: {
    type: Number,
    default: 30,
  },
});

const dataStore = ref([]);
const lastFilename = ref("");
const appState = useAppStateStore();
const authStore = useAuthStore();
const metaStore = useMetaStore();

onMounted(() => {
  events.on(props.dataMessage, onData);
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

async function onData(data) {
  if (props.strategy === "last") {
    clearDataStore();
  }
  if (
    props.autoSaveStrategy === "onOverflow" &&
    dataStore.value.length >= props.limit
  ) {
    await save();
  }
  dataStore.value.push(data);
  if (
    props.autoSaveStrategy === "onFull" &&
    dataStore.value.length >= props.limit
  ) {
    await save();
  }
}

function clearDataStore() {
  dataStore.value = [];
}

function deleteLastFrame() {
  if (dataStore.value.length > 0) {
    dataStore.value.pop();
  }
}

function generateFilename(uuid, date) {
  // format the date as "yyyyMMdd_hhmmss"
  let formattedDate = date
    .toISOString()
    .replace(/[-T:.Z]/g, "")
    .slice(0, 14);
  formattedDate = formattedDate.slice(0, 8) + "_" + formattedDate.slice(8);

  let additionalData = "";
  // We can use the metaStore to add additional data to the filename.
  // This is useful to quickly identify files that belong to a certain
  // experiment, for example.
  const infoData = metaStore.get("info");
  if (infoData?.includeInFilename) {
    // It might seem more intuitive to have the iteration over the
    // keys of stored in includeInFilename, but this way we can
    // be sure that the order of the keys is more consistent.
    console.log( infoData );
    for (const key in infoData) {
      if (infoData.includeInFilename.includes(key))
        additionalData += `_${infoData[key]}`;
    }
  }
  // Note that additionalData might be empty, but that's fine.
  // It will not affect the filename in that case.
  return `${formattedDate}${additionalData}_${uuid}.json`;
}

const forbiddenStoreNames = ["auth"];

function getAllStores() {
  const pinia = getActivePinia();
  const stores = {};
  for (const storeName in pinia.state.value) {
    if (!forbiddenStoreNames.includes(storeName)) {
      stores[storeName] = unref(pinia.state.value[storeName]);
    }
  }
  return stores;
}

async function save() {
  if (dataStore.value.length === 0) return;

  const uuid = uuid4();
  const date = new Date();
  const filename = generateFilename(uuid, date);

  lastFilename.value = filename;

  //console.log( getAllStores() );
  const saveData = {
    uuid: uuid,
    filename: filename,
    createdAt: date,
    data: dataStore.value,
    appId: appState.id,
    userId: authStore.user.id,
    store: getAllStores(),
  };
  await writeDataFile(filename, JSON.stringify(saveData));
  clearDataStore();
  // Refresh any file browser
  events.emit('control', 'browser.refresh');
}
</script>

<style>
.scanProgressIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.scan-in-progress {
  color: #ff6900;
}
.scan-done {
  color: #a1b966;
}
.scan-pending {
  color: #ccc;
}
</style>
