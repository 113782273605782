<template>
  <ion-card>
    <div class="specTelligenceScanColorOverlay" v-show="showOverlay">
      <div v-if="countDownDisplay" class="countdownNumber">
        {{ countDownDisplay }}
      </div>
    </div>
    <ion-card-header>
      <ion-card-title>specTelligence Scan</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col class="preview">
            <div class="cameraPreview"></div>
            <video id="cameraLivePreview" autoplay></video>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-list-header>
                <ion-label>Settings</ion-label>
              </ion-list-header>
              <ion-item
                v-for="(property, propertyName) of cameraSettings"
                :key="propertyName"
              >
                <ion-label position="floating"> {{ propertyName }} </ion-label>
                <ion-range
                  type="number"
                  :min="property.min"
                  :max="property.max"
                  :value="property.value"
                  :ticks="true"
                  :snaps="true"
                  @ionKnobMoveEnd="
                    settingChange(propertyName, $event.target.value)
                  "
                >
                </ion-range>
              </ion-item>
              <ion-item>
                <ion-label position="floating"> Countdown </ion-label>
                <ion-range
                  type="number"
                  :min="countdown.min"
                  :max="countdown.max"
                  :value="countdown.value"
                  :ticks="true"
                  :snaps="true"
                  @ionKnobMoveEnd="countdown.value = $event.target.value"
                >
                </ion-range>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
    <!-- <ion-button fill="outline" @click="startCamera">Start</ion-button> -->
    <ion-button 
      fill="outline" 
      :disable="isScanning" 
      @click="startScan"
    >
     Scan
    </ion-button>
    <!-- <ion-button fill="outline" @click="stopCamera">Stop</ion-button> -->
  </ion-card>
</template>

<script setup>
import {
  IonList,
  IonListHeader,
  IonRange,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { onMounted, onBeforeUnmount, ref } from "vue";
import { events } from "@/utils/events";
import { dataMessage } from "@/messages/dataMessage";

let overlay = undefined;
let track = undefined;
const exposureTime = ref(50);
const minExposureTime = ref(0);
const maxExposureTime = ref(0);
const exposureTimeChanging = ref(false);
const countDownDisplay = ref(0);

const countdown = ref({
  min: 0,
  max: 7,
  value: 3,
});

const captureDelay = ref(100);
const cameraSettings = ref({});

async function settingChange(propertyName, newValue) {
  console.log("settingChange:", propertyName, newValue);
  if (!track) {
    console.warn("No track available");
    return;
  }

  await track.applyConstraints({
    advanced: [
      {
        [propertyName]: newValue,
      },
    ],
  });
  console.log("settingChange:", track.getSettings()[propertyName]);
  console.log("settingChange:", track.getSettings());
  cameraSettings.value[propertyName].value = track.getSettings()[propertyName];
}

async function changeExposureTime(step) {
  let offset = 0;
  const initialExposureTime = track.getSettings().exposureTime;
  let newExposureTime = initialExposureTime;
  do {
    offset += step;
    console.log("Trying exposure time", initialExposureTime + offset);
    await track.applyConstraints({
      advanced: [
        {
          exposureTime: initialExposureTime + offset,
          brigthness: 10,
        },
      ],
    });
    newExposureTime = track.getSettings().exposureTime;
    if (newExposureTime > maxExposureTime.value) return maxExposureTime.value;
    if (newExposureTime < minExposureTime.value) return minExposureTime.value;
  } while (newExposureTime == initialExposureTime);
  return newExposureTime;
}

async function incrementExposureTime() {
  console.log("Incrementing exposure time");
  return await changeExposureTime(minExposureTime.value);
}

async function decrementExposureTime() {
  console.log("Decrementing exposure time");
  return await changeExposureTime(-minExposureTime.value);
}

async function setNewExposureTime(value) {
  exposureTimeChanging.value = true;
  let newExposureTime = 0;
  if (value > exposureTime.value) {
    newExposureTime = await incrementExposureTime();
  } else if (value < exposureTime.value) {
    newExposureTime = await decrementExposureTime();
  }
  console.log("New exposure time", newExposureTime);
  exposureTime.value = newExposureTime;
  exposureTimeChanging.value = false;
}

onMounted(() => {
  startCamera();
  overlay = document.querySelector(".specTelligenceScanColorOverlay");
  document.querySelector("body").appendChild(overlay);
});

onBeforeUnmount(() => {
  stopCamera();
  document.querySelector("body").removeChild(overlay);
});

function onCountdownChange(event) {
  console.log("Countdown changed", event.target.value);
  console.log(event);
}

let cameraStream = undefined;
let imageCanvas = undefined;

const isScanning = ref(false);
const showOverlay = ref(false);

async function startScan() {
  console.log("Start scan");
  setOverlayColor("#00000080");
  isScanning.value = true;
  showOverlay.value = true;
  let countdownValue = countdown.value.value;
  console.log("Countdown value", countdownValue);
  if (countdownValue > 0) {
    await new Promise((resolve) => {
      let interval = undefined;
      countDownDisplay.value = countdownValue;
      interval = setInterval(() => {
        countdownValue--;
        countDownDisplay.value = countdownValue;
        if (countdownValue <= 0) {
          clearInterval(interval);
          countDownDisplay.value = undefined;
          console.log("Countdown finished");
          resolve();
        }
      }, 1000);
    });
  }

  console.log("Start scan");
  imageCanvas = document.createElement("canvas");

  const video = document.getElementById("cameraLivePreview");
  imageCanvas.width = 3 * video.videoWidth;
  imageCanvas.height = 2 * video.videoHeight;
  captureImage();
}

const COLORS = ["#000000", "#FFFFFF", "#FF0000", "#00FF00", "#0000FF"];
const offsets = [
  { x: 1, y: 1 },
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 2, y: 0 },
  { x: 0, y: 1 },
];

function setOverlayColor(color) {
  overlay.style.backgroundColor = color;
}

function captureImage(index = 0) {
  let colorChangeDelay = (4 * (cameraSettings?.value?.exposureTime?.value || 100) ) / 10; // Exposure time is defined in multiples of 100us == 0.1ms
  if (colorChangeDelay < 100) colorChangeDelay = 100;
  if (index >= COLORS.length) {
    emitScanData();
    isScanning.value = false;
    showOverlay.value = false;
    return;
  }
  setOverlayColor(COLORS[index]);
  setTimeout(() => {
    takeSnapshot(index);
    setTimeout(() => captureImage(index + 1), captureDelay.value);
  }, colorChangeDelay);
}

function startCamera() {
  navigator.getUserMedia(
    {
      audio: false,
      video: { width: 320, height: 240 },
    },
    async function (stream) {
      cameraStream = stream;
      const video = document.getElementById("cameraLivePreview");
      video.srcObject = stream;
      track = stream.getVideoTracks()[0];

      const capabilities = track.getCapabilities();
      console.log("Camera capabilities:", capabilities);
      const settings = track.getSettings();
      console.log("Camera settings:", settings);

      const desiredProperties = [
        "exposureTime",
        "exposureCompensation",
        "brightness",
      ];
      for (const property of desiredProperties) {
        if (!(property in settings)) {
          console.warn(`Camera settings do not contain property ${property}`);
          continue;
        }
        cameraSettings.value[property] = capabilities[property];
        cameraSettings.value[property].value = settings[property];
      }
      track.applyConstraints({
        advanced: [
          {
            exposureMode: "manual",
            whiteBalanceMode: "manual",
            colorTemperature: 6500,
          },
        ],
      });
    },
    function (error) {
      console.log("getUserMedia error: ", error);
    }
  );
}

function takeSnapshot(index) {
  const video = document.getElementById("cameraLivePreview");
  const width = video.videoWidth;
  const height = video.videoHeight;
  const offsetX = width * offsets[index].x;
  const offsetY = height * offsets[index].y;
  const compositeOffsetX = width * 2;
  const compositeOffsetY = height * 1;
  console.log({ index, offsetX, offsetY });
  const context = imageCanvas.getContext("2d");

  context.globalCompositeOperation = "source-over";
  context.drawImage(
    video,
    offsetX,
    offsetY,
    width,
    height
  );

  // substract black data
  // get image data
  if ( index != 0 ) { // index 0 is black image, so no need for substraction here
    const imageData = context.getImageData(
      offsetX,
      offsetY,
      width,
      height
    );

    const blackData = context.getImageData(
      width * offsets[0].x,
      height * offsets[0].y,
      width,
      height
    );

    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = imageData.data[i] - blackData.data[i];
      if ( imageData.data[i] < 0 ) imageData.data[i] = 0;
      imageData.data[i + 1] = imageData.data[i + 1] - blackData.data[i + 1];
      if ( imageData.data[i + 1] < 0 ) imageData.data[i + 1] = 0;
      imageData.data[i + 2] = imageData.data[i + 2] - blackData.data[i + 2];
      if ( imageData.data[i + 2] < 0 ) imageData.data[i + 2] = 0;
    }
    context.putImageData(imageData, offsetX, offsetY);
  }

  if (index === 0) {
    context.fillMode = "black";
    context.fillRect(
      compositeOffsetX,
      compositeOffsetY,
      width,
      height
    );
  } else {
    // Draw composite image with lighter mode
    context.globalCompositeOperation = "lighter";
    context.drawImage(
      context.canvas,
      offsetX,
      offsetY,
      width,
      height,
      compositeOffsetX,
      compositeOffsetY,
      width,
      height
    );
  }
  // Reset to default
  context.globalCompositeOperation = "source-over";
}

function emitScanData() {
  const imageData = imageCanvas.toDataURL("image/png");
  const data = dataMessage();
  data.type = "image";
  data.source = "SpecTelligenceScanModule";
  data.shape = [imageCanvas.height, imageCanvas.width];
  data.values = imageData;
  data.config = { colors: COLORS, offsets: offsets, cameraSettings: cameraSettings.value };
  events.emit("data", data);
}

function stopCamera() {
  if (!cameraStream) return;
  cameraStream.getTracks().forEach((track) => track.stop());
}
</script>

<style scoped>
.preview {
  display: flex;
  justify-content: center;
}
.specTelligenceScanColorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5001;
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdownNumber {
  font-size: 10rem;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 10px #000;
}
</style>
