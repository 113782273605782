import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import LoginView from "@/views/auth/LoginView.vue";
import ProfileView from "@/views/auth/ProfileView.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";
import PrefetchGuard from "@/views/common/PrefetchGuard.vue";
import AboutView from "@/views/common/AboutView.vue";
import HomeView from "@/views/common/HomeView.vue";
import SettingsView from "@/views/common/SettingsView.vue";
import AppContainer from "@/views/app/AppContainer.vue";
import AppSelector from "@/views/app/AppSelector.vue";
import AdminMenu from "@/views/admin/AdminMenu.vue";
import AdminAppList from "@/views/admin/AppList.vue";
import AppEdit from "@/views/admin/AppEdit.vue";
import UserList from "@/views/admin/UserList.vue";
import AdminDataExplorer from "@/views/admin/DataExplorer.vue";

import { useAuthStore } from "@/store/auth.js";

import stringConst from '../assets/strings';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/profile",
  },
  {
    name: stringConst.routes.LOGIN,
    path: "/login",
    component: LoginView,
  },
  {
    name: stringConst.routes.FORGOT_PASSWORD,
    path: "/forgotPassword",
    component: ForgotPassword,
  },
  {
    name: stringConst.routes.RESET_PASSWORD,
    path: "/resetPassword",
    component: PasswordReset,
  },
  {
    name: stringConst.routes.PREFETCH_GUARD,
    path: "/prefetchGuard",
    component: PrefetchGuard,
  },
  {
    name: stringConst.routes.PROFILE,
    path: "/profile",
    component: ProfileView,
  },
  {
    name: stringConst.routes.APP_SELECTION,
    path: "/app",
    component: AppSelector,
  },
  {
    name: stringConst.routes.APP,
    path: "/app/:id",
    component: AppContainer,
  },
  {
    name: stringConst.routes.HOME,
    path: "/home",
    component: HomeView,
  },
  {
    name: stringConst.routes.SETTINGS,
    path: "/settings",
    component: SettingsView,
  },
  {
    name: stringConst.routes.CREDITS,
    path: "/about",
    component: AboutView,
  },
  {
    name: stringConst.routes.ADMIN,
    path: "/admin",
    component: AdminMenu,
  },
  {
    name: stringConst.routes.ADMIN_USER_MANAGEMENT,
    path: "/admin/users",
    component: UserList,
  },
  {
    name: stringConst.routes.ADMIN_APP_MANAGEMENT,
    path: "/admin/apps",
    component: AdminAppList,
  },
  {
    name: stringConst.routes.ADMIN_DATAEXPLORER,
    path: "/admin/dataFiles",
    component: AdminDataExplorer,
  },
  {
    name: stringConst.routes.ADMIN_APP_EDIT,
    path: "/admin/apps/edit/:id",
    props: route => ({ id: route.params.id }),
    component: AppEdit,
  },
  {
    name: stringConst.routes.ADMIN_USER_EDIT,
    path: "/admin/users/edit/:id",
    props: route => ({ id: route.params.id }),
    component: ProfileView,
  },
  {
    name: stringConst.routes.AMDIN_APP_CREATE,
    path: "/admin/apps/new",
    props: {
      id: 'new',
    },
    component: AppEdit,
  },
  {
    name: stringConst.routes.ADMIN_APP_COPY,
    path: "/admin/apps/copy/:id",
    props: route => ({ id: route.params.id }),
    component: AppEdit,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const unauthenticatedRoutes = [
  "Login",
  "Forgot Password",
  "Reset Password",
  "Prefetch Guard",
  "Download File"

];
// eslint-disable-next-line
router.beforeEach(async (to, from) => {
  console.log({ to, from });
  if (unauthenticatedRoutes.includes(String(to.name))) {
    return true;
  }
  const auth = useAuthStore();
  if ( !auth.isLoggedIn() ) {
    // redirect the user to the login page
    return { name: "Login" };
  }else{
    if(to.fullPath.includes("/admin") && !auth.user.admin){
      return { name: "Home" };
    }else{
      return true;
    }

  }

  //return true;
});

export default router;
