import { Storage } from '@ionic/storage';

const debugStorage = false;
function debugLog( ...args: any[] ) {
  if ( debugStorage ) {
    console.log( ...args );
  }
}
function setupStorage() {
  const store: any = new Storage();
  store.setItem = (key :any, value:any) => {
    debugLog( 'setting', key, value);
    return store.set( key, value);
  };
  store.getItem = async ( key: any ) => {
    const value = await store.get( key );
    debugLog( 'getting', key, value ); 
    return value;
  }
  store.removeItem = async ( key:any ) => {
    debugLog( 'removing', key );
    return await store.remove( key );
  }
  const tempFn = async () => {
    debugLog( 'Creating storage...');
    await store.create();
    debugLog( 'Storage created successfully');
  }
  tempFn();
  return store;
}

const storage = setupStorage();

export {
  storage
}