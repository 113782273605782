<template>
  <ion-page>
    <ion-content>
     <ion-row class="ion-justify-content-center">
      <ion-col size="12" size-sm="8">
       <ion-card>
        <ion-card-header>
          <ion-card-title> Apps </ion-card-title>
        </ion-card-header>
        <ion-card-content>    
          <ion-list>
            <ion-item color="primary" href="admin/apps/new">
              <ion-icon slot="end" class="bi bi-plus-circle" />
              Add a new App
            </ion-item>
            <ion-item
              v-for="app of availableApps"
              button="true"
              lines="full"
              :key="app.id"
              :href="'admin/apps/edit/' + app.id "
            >
              <ion-icon
                v-if="app.icon"
                slot="start"
                :class="'bi bi-' + app.icon"
              />
              <ion-label>
                <h2>{{ app.name }}</h2>
                <p>{{ app.description }}</p>
              </ion-label>
              <ion-item>
               <ion-icon class="bi bi-pencil" />
              </ion-item>
              <ion-item 
                class="disable-hover"                
                :href="'admin/apps/copy/' + app.id ">
               <!--<ion-icon class="bi bi-c-square" />-->
               <ion-icon style="font-size: 28px;" :icon="copy" />
              </ion-item>
            </ion-item>
            
          </ion-list>
        </ion-card-content>
       </ion-card>
      </ion-col>
     </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonList, IonIcon, IonRow, IonCol } from "@ionic/vue";
import { onMounted, ref } from "vue";
import { getAvailableApps } from "@/supabase/app";
import { copy } from "ionicons/icons";

const availableApps = ref([]);

onMounted(async () => {
  availableApps.value = await getAvailableApps();
});
</script>

<style scoped>
.disable-hover {
  --background-hover: none;
}
</style>