<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>File Example</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <h2>Create / Save File</h2>
      <ion-input v-model="fileName" placeholder="File Name"></ion-input>
      <ion-textarea
        label-placement="floating"
        fill="solid"
        placeholder="File Content"
        v-model="fileContent"
      >
      </ion-textarea>
      <ion-buttons>
      <ion-button @click="saveFile">Save File</ion-button>
      <ion-button @click="saveFile2">Save File with any rights</ion-button>
      </ion-buttons>

      
      <h2>Load File</h2>

      <ion-select label="File" v-model="selectedFile" placeholder="Select File">
        <ion-select-option v-for="file in availableFiles" :key="file" :value="file">{{ file }}</ion-select-option>
      </ion-select>
      <ion-buttons>
        <ion-button @click="listLocalFiles">Refresh</ion-button>
        <ion-button @click="readFile">Read File</ion-button>
        <ion-button @click="deleteFile">Delete File</ion-button>
      </ion-buttons>

      
      <h2>Download from supabase</h2>

      <img v-if="imageBlob" :src="imageBlob" alt="image" />
      <ion-buttons>
        <ion-button @click="donwloadFileFromSupabase">Download</ion-button>
      </ion-buttons>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { ref } from "vue";
import { IonButton, IonSelect, IonSelectOption, IonTextarea, onIonViewDidEnter } from "@ionic/vue";
import { useFileStore } from "@/store/file";
import { listFiles, downloadFile } from "@/supabase/file";

const fileName = ref("test.txt");
const fileContent = ref("some content");
const selectedFile = ref(null);
const availableFiles = ref([]);

const imageBlob = ref(null); 

const fileStore = useFileStore();

onIonViewDidEnter(() => {
  listLocalFiles();
});

const listLocalFiles = async () => {

  const files = await fileStore.listFiles();
  console.log(files);
  availableFiles.value = files;
};

const saveFile = async () => {
  const file = await fileStore.saveFile(fileName.value, fileContent.value);
};
const saveFile2 = async () => {
  const file = await fileStore.saveFile(fileName.value, {test: fileContent.value, blubb: "foo", num:42}, {
    user: undefined,
    app: undefined,
  });
};

const readFile = async () => {
  console.log("selectedFile", selectedFile.value);
  fileName.value = selectedFile.value;
  fileContent.value = await fileStore.getFile(fileName.value);
  console.log("fileContent", fileContent.value);
};

const deleteFile = async () => {
  await fileStore.deleteFile(selectedFile.value);
  listFiles();
};

async function donwloadFileFromSupabase() {

  const bucketName = "cicada-example";
  const fileName = "adi-goldstein-EUsVwEOsblE-unsplash.jpg";

  const filesList = await listFiles(bucketName);
  console.log(filesList);

  const file = await downloadFile(bucketName, fileName);
  console.log(file);
  if ( file ) {
    const blob = new Blob([file], { type: "image/jpeg" });
    imageBlob.value = URL.createObjectURL(blob);
  }
}

</script>

<style></style>
