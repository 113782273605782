<template>
   <div :class="getCorrectAlignment()">
      <ion-fab slot="fixed" :vertical="props.vertical_position" :horizontal="props.horizontal_position">
       <ion-fab-button @click="sendControlMessage()">
        <ion-icon :class="'bi bi-' + props.icon" ></ion-icon>
       </ion-fab-button>
      </ion-fab>
    </div>
</template>

<script setup>
  import { IonFab, IonFabButton, IonIcon } from '@ionic/vue';
  import { events } from "@/utils/events";
  
  const props = defineProps({
    message: { 
        type: String, 
        default:"A control message"
    },
    icon: { 
        type: String, 
        default:"plus"
    },
    vertical_position: { 
        type: String, 
        default:"top",
        choice: ["top", "bottom"]
    },
    horizontal_position: { 
        type: String, 
        default:"end",
        choice: ["end", "start"]
    },
    background_color: {
      type: String, 
      default:"#9bbb59",
    }
  });

  function sendControlMessage(){
    console.log(props.message);
    events.emit('control', props.message);
  }

  function getCorrectAlignment(){
    if(props.vertical_position == "bottom" && props.horizontal_position == "end"){
      return 'fixedBottom_right';
    }else if(props.vertical_position == "bottom" && props.horizontal_position == "start"){
      return 'fixedBottom_left';
    }else if(props.vertical_position == "top" && props.horizontal_position == "end"){
      return 'fixedTop_right';
    }else{
      return 'fixedTop_left';
    }
  }
</script>
<style scoped>
.fixedBottom_right {
    position: fixed;
    bottom: 70px;
    right: 0;
}

.fixedBottom_left {
    position: fixed;
    bottom: 70px;
    left: 0;
}

.fixedTop_right {
    position: fixed;
    top: 30px;
    right: 0;
}

.fixedTop_left {
    position: fixed;
    top: 30px;
    left: 0;
}

ion-fab-button {
  --background: v-bind('props.background_color')
}
</style>