<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-button @click="onContinue">
        Continue
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {useRouter } from 'vue-router';

const router = useRouter();
const continue_url = router?.currentRoute?.value?.query?.continue_url;

function onContinue() {
  if (! continue_url ) {
    router.push( {name:'login'});
  }
  console.log( 'forwarding to ', continue_url );
  window.location.replace( continue_url );
}
</script>