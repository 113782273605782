<template>
  <ion-item>
    <ion-label v-if="modelValue !== null" position="floating">
      {{ label }}
    </ion-label>
    <ion-label v-else position="floating">{{ hint }}</ion-label>
    <ion-input :placeholder="hint" :value="modelValue" @input="updateValue">
    </ion-input>
  </ion-item>
</template>

<script setup>

const emit = defineEmits(["update:modelValue"]);
// eslint-disable-next-line
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  hint: {
    type: String,
    default: "",
  },
});

function updateValue($event) {
  emit("update:modelValue", $event.target.value);
}
</script>

<style></style>
