export default {
  routes: {
    HOME: 'Home',
    LOGIN: 'Login',
    PROFILE: 'Profile',
    SETTINGS: 'Settings',
    CREDITS: 'Credits',
    APP: 'App',
    APP_SELECTION: 'App Selection',
    FORGOT_PASSWORD: 'Forgot Password',
    RESET_PASSWORD: 'Reset Password',
    PREFETCH_GUARD: "Prefetch Guard",
    ADMIN: "Admin",
    ADMIN_USER_MANAGEMENT: "User Management",
    ADMIN_APP_MANAGEMENT: "App Management",
    ADMIN_DATAEXPLORER: "Data Explorer",
    ADMIN_APP_EDIT: "Edit App",
    ADMIN_APP_CREATE: "Create App",
    ADMIN_APP_COPY: "Copy App",
    FILE_DOWNLOAD: "Download File",

    
  }
}