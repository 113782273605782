
// const BASE_URL = 'http://localhost:8100';
//const BASE_URL = 'https://cicada2.spectelligence.com';
const BASE_URL = 'https://cicada.compolytics.com';

export function getAbsoluteLink( subRoute : string ) : string {
  return BASE_URL + subRoute;
}

export function isDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function isDataURL(str : string) {
  return /^data:([a-z]+\/[a-z]+)?(;base64)?,(.*)$/.test(str);
}
export function isPNGDataURL(str : string) {
  return /^data:image\/png(;base64)?,/.test(str);
}
export function limitNumber(number : number, min : number, max : number) {
  return Math.min(Math.max(number, min), max);
}

export function mailWasSent(){
  return "Notes: After sending an email a new email should only be sent after "+timeLimitForSendingMails()+" minutes in order to avoid errors when sending."
}

export function sendingMailMustWait(val: number){
  return "A new email can only be sent after "+timeLimitForSendingMails()+" minutes in order to avoid errors when sending. You must wait for "+val+" minutes."
}

export function timeLimitForSendingMails(){
  //return max. minutes
  return 15;
}

export function calculateTimestampDifference(old:number, current:number) {
  const difference = current - old;
  //difference in minutes
  return Math.floor(difference / 1000 / 60);
}