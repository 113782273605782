import { defineStore } from "pinia";
import { ref } from "vue";


const storeId = "mail_send";
export const mailSentStore = defineStore(storeId, () => {
  const sendMail = ref({});
  function setTimestampByMail(email, newTimestamp ){
    sendMail.value[email] = newTimestamp;
  }
  
  return { sendMail, setTimestampByMail };
},
{persist: true },);