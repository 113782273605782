<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> Matrix </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div id="MeasurementDataMatrix">
        <div
          style="display: flex; justify-content: center"
          v-if="isRangeAdjustable && values && values.length > 0"
        >
          <ion-range
            v-model="maxValuePower"
            :min="0"
            :max="16"
            step="1"
            :ticks="true"
            :snaps="true"
            :pin-formatter="pinFormatter"
          />
          <span class="ms-2 maxValueIndicator">
            {{ maxValue }}
          </span>
        </div>
        <div id="dataMatrixContainer">
          <table style="border-spacing=2px;">
            <thead>
              <tr>
                <th />
                <th />
                <th :colspan="values[0].length" class="text-center">
                  {{ xAxisTitle }}
                </th>
              </tr>
              <tr>
                <th />
                <th />
                <th
                  v-for="label in lastDataMessage?.meta?.xLabel || []"
                  v-bind:key="label + Math.random().toString(16).slice(4)"
                  class="text-center"
                  scope="col"
                >
                  {{ label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, rowIndex) in values" v-bind:key="data">
                <!-- Add the y Axis label in the first iteration of the loop -->
                <th
                  v-if="rowIndex == 0"
                  scope="row"
                  :rowspan="values.length"
                  class="text-center p-0 vertical"
                >
                  {{ yAxisTitle }}
                </th>
                <!-- Add a label to each row, -->                  
                <th class="pr-3" scope="row">
                  {{
                    lastDataMessage?.meta?.yLabel?.length > rowIndex ?
                    lastDataMessage?.meta?.yLabel[ rowIndex ] : ''
                  }}
                </th>
                <!-- Loop over the actual row and add the data. -->
                <td
                  v-for="value in data"
                  v-bind:key="value"
                  :style="{
                    backgroundColor: getColor(rowIndex, value),
                  }"
                  :class="value >= 65535 ? 'saturation' : ''"
                >
                  <div class="measurement-matrix-entry">
                    {{ numeralFormat(value, formatString) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonRange,
} from "@ionic/vue";

import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import { events } from "@/utils/events";
import { isDarkMode } from "@/utils/index";
import interpolate from "color-interpolate";

const props = defineProps({
  rowLabelsString: {
    type: String,
    default: () => {
      return "";
    },
  },
  columnLabelsString: {
    type: String,
    default: () => {
      return "";
    },
  },
  colors: {
    type: String,
    default: () => {
      return "";
    },
  },
  yAxisTitle: {
    type: String,
    default: "value",
  },
  xAxisTitle: {
    type: String,
    default: "",
  },
  adjustableRange: {
    type: String,
    default: "true",
  },
  fixedRange: {
    type: Number,
    default: 12,
  },
});

const isRangeAdjustable = computed(() => {
  return props.adjustableRange === "true";
});

const maxValuePower = ref(props.fixedRange);
const maxValue = computed(() => {
  return Math.pow(2, maxValuePower.value);
});
const formatString = ref("0");

function pinFormatter(value) {
  return value.toString();
}

const values = ref([[]]);
const lastDataMessage = ref(undefined);

function getColor(colorIndex, value) {
  const backgroundColor = isDarkMode() ? "#000000" : "#ffffff";
  let colorInputValues = [];
  if ( lastDataMessage.value?.meta?.xColor ) {
    colorInputValues = lastDataMessage.value.meta.xColor;
  }

  const targetColor = colorInputValues.at(colorIndex)
    ? colorInputValues.at(colorIndex)
    : isDarkMode()
    ? "white"
    : "black";

  const colormap = interpolate([backgroundColor, targetColor]);
  return colormap(value / maxValue.value);
}

function updateChart(dataMessage) {
  lastDataMessage.value = dataMessage;
  values.value = dataMessage.values;
}

onMounted(() => {
  events.on("data", updateChart);
});

onBeforeUnmount(() => {
  events.off("data", updateChart);
});
</script>

<style scoped>
#MeasurementDataMatrix {
  overflow: hidden;
}

#dataMatrixContainer {
  padding-bottom: 1em;
  margin-bottom: 1em;
  overflow-x: auto;
}

#dataMatrixContainer table {
  margin-left: auto;
  margin-right: auto;
}

th.vertical {
  writing-mode: vertical-lr;
  padding: 0px;
}

td {
  border-radius: 10px;
  aspect-ratio: 1;
  height: 3.3em;
}

.saturation {
  border-color: #821a1a !important;
  border-top-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: solid !important;
  border-left-style: solid !important;
  border-top-width: thick !important;
  border-right-width: thick !important;
  border-bottom-width: thick !important;
  border-left-width: thick !important;
}

.measurement-matrix-entry {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  color: rgb(177, 177, 177);
  mix-blend-mode: difference;
  height: 100%;
  overflow: hidden;
}

.maxValueIndicator {
  min-width: 4em;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
