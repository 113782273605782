<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> Change these settings </ion-card-title>
              <ion-card-subtitle>
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              Here are some settings (added in future).
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
</script>

<style></style>