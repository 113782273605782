<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        ScanCorder
        <template v-if="isConnected">
          <ion-icon
            v-if="powerStatus.batteryLevel > 60"
            style="float: right"
            class="bi bi-battery-full"
          ></ion-icon>
          <ion-icon
            v-else-if="powerStatus.batteryLevel > 30"
            style="float: right"
            class="bi bi-battery-half"
          ></ion-icon>
          <ion-icon
            v-else
            style="float: right"
            class="bi bi-battery"
          ></ion-icon>
        </template>
      </ion-card-title>
      <ion-card-subtitle v-if="isConnected">{{
        deviceInfo.name
      }}</ion-card-subtitle>
      <template> </template>
    </ion-card-header>
    <ion-card-content>
      <ion-grid v-if="connectionInProgress">
        <ion-row>
          <ion-col>
            <ion-spinner />
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="isConnected">
        <ion-row>
          <ion-col>Device Name</ion-col>
          <ion-col>{{ deviceInfo.name }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>FW / HW Version</ion-col>
          <ion-col
            >{{ deviceInfo.firmwareVersion }} /
            {{ deviceInfo.hardwareVersion }}</ion-col
          >
        </ion-row>
        <ion-row>
          <ion-col>Connected Sensor</ion-col>
          <ion-col>
            <template v-if="deviceInfo.sensorHead">
              <data-tree :data="deviceInfo.sensorHead"> </data-tree>
            </template>
            <template v-else> No sensor connected </template>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>ExposureTime [ms]</ion-col>
          <ion-col>
            <ion-input
              type="number"
              min="1"
              max="1000"
              v-model="exposureTime"
            />
          </ion-col>
          <ion-col>
            <ion-button @click="setExposureTime">set</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Gain</ion-col>
          <ion-col>
            <ion-input
              type="number"
              min="0"
              max="10"
              v-model="gain"
            />
          </ion-col>
          <ion-col>
            <ion-button @click="setGain">set</ion-button>
          </ion-col>
        </ion-row>
        <ion-row v-if="deviceInfo?.sensorHead?.driver === 0">
          <ion-col>LED current [mA]</ion-col>
          <ion-col>
            <ion-input
              type="number"
              min="4"
              max="150"
              v-model="ledCurrent"
            />
          </ion-col>
          <ion-col>
            <ion-button @click="setLedCurrent">set</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
    <ion-button
      fill="clear"
      :disabled="connectionInProgress"
      v-if="!isConnected"
      @click="connectToDevice"
    >
     Connect
    </ion-button>
    <ion-button
      fill="clear"
      :disabled="connectionInProgress"
      v-if="isConnected"
      @click="promptUserForDisconnect"
    >
    Disconnect
    </ion-button>
    <ion-button v-if="isConnected" @click="startMeasurement">scan!</ion-button>
    <ion-loading :is-open="measurementInProgress" message="Measuring, please wait..." spinner="crescent"/>
    <ion-alert :is-open="alertForceCalibration" header="No Calibration" message="Please calibrate device first!" :buttons="[{text: 'OK',handler: resetAlertCalibration}]"/>
    <ion-row>
      <ion-col>
        <ion-card-subtitle v-if="isConnected">
          Sensor Calibration
        </ion-card-subtitle>
        <ion-card-content v-if="isConnected">
          <ion-grid>
            <ion-row>
              <ion-col>True Reflectance Value [%]</ion-col>
              <ion-col>
                <ion-input type="number" min="1" max="100" step="1" v-model="calibrationInfo.trueValuePercentage" @ionBlur="checkTrueValueInput" @ionInput="checkTrueValueInput"/>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Calibration Data</ion-col>
              <ion-col>
                <template v-if="displayCalibration">
                  <data-tree :data="displayCalibration"> </data-tree>
                </template>
                <template v-else-if="!calibrationMode"> No Calibration</template>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-button v-if="isConnected" fill="clear" @click="clearCalibration">Clear Calibration</ion-button>
      <ion-button v-if="isConnected" @click="startCalibration">Calibrate Sensor</ion-button>
    </ion-row>
  </ion-card>
</template>

<script setup>
import {
  IonButton,
  IonCardContent,
  IonCard,
  IonInput,
  IonLoading,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonCardSubtitle,
} from "@ionic/vue";

import { ref, onMounted, onBeforeUnmount } from "vue";
import { BleDevice, requestDevice } from "../utils/BleDevice";
import { events } from "@/utils/events";
import DataTree from "@/components/DataTree.vue";
import { useMetaStore } from "@/store/meta";
import { dataMessage } from "@/messages/dataMessage";
import { colorFromWavelength } from "@/utils/color";
import { InvalidBooleanError } from "web3";

const BLE_UUIDS = {
  deviceService: {
    uuid: "a036d97c-3e15-4e6c-85af-9bd7627cd9b8",
    characteristics: {
      deviceName: "39ff4e8c-87f4-4c81-bcd7-d86a03338e1f",
      firmwareVersion: "5f031591-3ddd-4013-a859-fbd21f7bc077",
      hardwareVersion: "8b17065e-cac2-40b4-bd99-9e4ca6a59685",
    },
  },
  powerService: {
    uuid: "0000180f-0000-1000-8000-00805f9b34fb",
    characteristics: {
      batteryLevel: "00002a19-0000-1000-8000-00805f9b34fb",
      batteryVoltage: "daef0566-6dfa-433f-b697-92f4aabe4ef4",
      usbVoltage: "c8336f9b-412e-41ae-9ed9-2b88998bdbf9",
    },
  },
  sensorheadService: {
    uuid: "042a81c6-92e7-4bbd-b1aa-d06eaafcf337",
    characteristics: {
      connected: "3d343df0-c1fc-4c81-9a47-286c6e079a28",
      sensortype: "dbb5cb20-f46a-4c52-9f2f-a754d5b7b6ed",
      sensorSerial: "5af1da71-166a-4be7-a6a5-edd3077ccd8a",
      sensorConfiguration: "9eea4959-ee8d-45cc-8af1-46f62c71f64d",
      sensorStatistics: "8fc9d974-a253-444d-a2f5-f8892e60f721",
      measurementData: "b74506cb-9b0b-4245-a1bc-7ee3dfb046ae",
      measurementControl: "ddae3746-63ef-4f97-a4a0-ebe241bbab31",
      exposureTime: "b524c744-afcf-4a85-ae76-a9f19915695a",
      gain: "07df4055-a985-4bfd-a470-8426cf5d19b4",
      ledCurrent: "da40175c-e1dc-46a3-bc79-0ae2b2f8f40a",
      additionalInfo: "5b652fcf-1c68-4065-889a-0a66b61f1eb2",
    },
  },
  otaProgrammerService: {
    uuid: "f959feb2-a75d-4927-9429-18432eebc795",
    characteristics: {
      error: "cb9de500-f0d3-445c-a0b6-7e0dad79a99b",
      firmwareInfo: "0ea9d32a-82d1-44e1-965f-24e1cada1b93",
      firmwareUpload: "90747c62-d67f-438a-8180-cbb840e1e362",
    },
  },
};
BLE_UUIDS.allServices = [
  BLE_UUIDS.deviceService.uuid,
  BLE_UUIDS.powerService.uuid,
  BLE_UUIDS.sensorheadService.uuid,
  BLE_UUIDS.otaProgrammerService.uuid,
];

const BLE_REQUEST_OPTIONS = {
  //services: [BLE_UUIDS.deviceService.uuid],
  namePrefix: "Comp",
  optionalServices: BLE_UUIDS.allServices,
};

const metaStore = useMetaStore();
const isConnected = ref(false);
const connectionInProgress = ref(false);

// Calibration info Structure
const calibrationInfo = ref({
  trueValuePercentage: 99,
});

const deviceInfo = ref({
  name: "",
  firmwareVersion: "0",
  hardwareVersion: "0",
  type: "unknown",
  serial: null,
  sensorHead: {
    serial: null,
    type: 0,
    name: "",
    sensor: 0,
    driver: 0,
    statistiscs: {
      boot: 0,
      measurements: 0,
      writes: 0,
      length: 0,
    },
    additionalInfo: {},
  },
  parameters: {
    gain: 0,
    exposureTime: 0,
    ledCurrent: 0,
  },
});

const powerStatus = ref({
  batteryLevel: 0,
  batteryVoltage: 0,
  usbVoltage: 0,
});

const batteryLevel = ref(undefined);
const bleDeviceId = ref(null);
let bleDevice = undefined;
const ledCurrent = ref(0);
const gain = ref(0);
const exposureTime = ref(0);

// Flag if the device is in calibration mode
const calibrationMode = ref(false);
// List of calibration data
const calibrationList = ref([]);
// Display calibration data
const displayCalibration = ref(null);
// Flag that measurement is in progress
const measurementInProgress = ref(false);
// Flag that alert is in progress
const alertForceCalibration = ref(false); 

const controlMessages = {
  "scancorder.startMeasurement": startMeasurement,
  "scancorder.startCalibration": startCalibration,
}

let resetStateTimeout = null;

// Define the props for the component which are default values
const props = defineProps({
  // Default Wallet is Compolytics Demo Safe Global @ Gnosis Chain
  forceCalibration: {
    type: String,
    default: "false",
    choice: ["false", "true"],
  }
 
});

onMounted(() => {
  events.on("control", onControl);
  calibrationList.value = [];
});

onBeforeUnmount(() => {
  events.off("control", onControl);
});

function onControl(message) {
  console.log("onControl", message);
  for (const key in controlMessages) {
    if ( key === message ) {
      controlMessages[key]();
    }
  }
}

function checkTrueValueInput(event) {
  const value = parseFloat(event.target.value);
  if (value <= 0) {
    calibrationInfo.value.trueValuePercentage = 1;
  } else if (value > 100) {
    calibrationInfo.value.trueValuePercentage = 100;
  // In case we have a NaN value, we set it to 99
  } else if (isNaN(value)) {
    calibrationInfo.value.trueValuePercentage = 99;
  } else {
    calibrationInfo.value.trueValuePercentage = value;
  }
}

function getEstimatedMeasurementDuration() {
  const MINIMUM_MEASUREMENT_TIME = 10000;
  const measureMentTime = 1000 + exposureTime.value * 2 * (deviceInfo.value?.additionalInfo?.led_wl?.length || 12);
  return Math.max(MINIMUM_MEASUREMENT_TIME, measureMentTime);
}

async function onMeasurementDataFromDevice(sensorData) {
  
  if (calibrationMode.value) {
    
    // check if there is a dark current measurement included
    // in the measurement data.
    // a dark current measurement is prepended to the actual data.
    let darkCurrent = undefined;
    const expectedNumberOfValues = deviceInfo.value.sensorHead.additionalInfo.led_wl.length;
    const actualNumberOfValues = sensorData.length;
    if (actualNumberOfValues === (expectedNumberOfValues + 1)) {
      // If a dark current is included, store it separately.
      darkCurrent = sensorData[0];
      // and remove it from the actual data
      sensorData = sensorData.slice(1);
    }

    // Calibration mode, do not send data    
    // Make calibration entry
    const calibInfo = { 
      trueValuePercentage: parseFloat(calibrationInfo.value.trueValuePercentage) || calibrationInfo.value.trueValuePercentage,
      trueValueFactor: calibrationInfo.value.trueValuePercentage  / 100.0,
      darkCurrent: darkCurrent,
      sensorValue: sensorData,
     };

    // Add data and true value to list
    calibrationList.value.push({...calibInfo});
    // Make calibration known
    displayCalibration.value = calibrationList.value;
    // Signal Calibration Mode is over
    calibrationMode.value = false;
     
  } else {

    if ( !calibrationMode.value && props.forceCalibration == "true" && calibrationList.value.length == 0 )
    {
      alertForceCalibration.value = true;
      return;    
    }

    const data = dataMessage();
    data.source = "ScanCorderModule";

    const expectedNumberOfValues = deviceInfo.value.sensorHead.additionalInfo.led_wl.length;
    const expectedNumberOfSensors = deviceInfo.value.sensorHead.additionalInfo.sensor_wl.length;
    const actualNumberOfValues = sensorData.length;
    const actualNumberOfSensors = sensorData[0].length;

    // check if there is a dark current measurement included
    // in the measurement data.
    // a dark current measurement is prepended to the actual data.
    let darkCurrent = undefined;
    if (actualNumberOfValues === (expectedNumberOfValues + 1)) {
      // If a dark current is included, store it separately.
      darkCurrent = sensorData[0];
      // and remove it from the actual data
      sensorData = sensorData.slice(1);
    }

    data.shape = [sensorData.length, sensorData[0].length];
    data.type = "raw";
    data.config = deviceInfo.value;
    data.config.power = powerStatus.value;
    data.values = sensorData;
    data.calibration = calibrationList.value;
    data.darkCurrent = darkCurrent;

    data.meta = {
      xLabel: deviceInfo.value.sensorHead.additionalInfo.sensor_wl,
      yLabel: deviceInfo.value.sensorHead.additionalInfo.led_wl,
    };
    
    // Compute display colors
    // Only if applicable
    if (deviceInfo?.value?.sensorHead?.additionalInfo?.led_wl) {
      const xColor = [];
      deviceInfo.value.sensorHead.additionalInfo.led_wl.forEach((wl) => {
        xColor.push(colorFromWavelength(wl));
      });
      data.meta.xColor = xColor;
    }

    events.emit("data", data);
    console.log("Data Emitted", data);
  }
  
  measurementInProgress.value = false;
  if (resetStateTimeout) {
    clearTimeout(resetStateTimeout);
    resetStateTimeout = null;
  }  
}

async function connectToDevice() {
  console.log("connectToDevice");
  connectionInProgress.value = true;
  try {

    // We clear calibration first
    clearCalibration();

    bleDeviceId.value = undefined;

    const deviceId = await requestDevice(BLE_REQUEST_OPTIONS);
    if (!deviceId) {
      throw new Error("No device found");
    }
    bleDeviceId.value = deviceId.deviceId;
    bleDevice = BleDevice(bleDeviceId.value);
    if (!(await bleDevice.connect())) {
      throw new Error("Could not connect to device");
    }
    console.log(await bleDevice.getServices());
    await readDeviceInfo();
    await subscribeToPowerInformation();
    await subscribeToMeasurementData();
    saveMeta();
    isConnected.value = true;
  } catch (error) {
    console.log("Error connecting to device", error);
  }
  connectionInProgress.value = false;
}

function saveMeta() {
  deviceInfo.value.parameters = {
    gain: gain.value,
    exposureTime: exposureTime.value,
    ledCurrent: ledCurrent.value,
  };
  metaStore.set("scancorder", deviceInfo.value);
}

async function readDeviceInfo() {
  console.log("readDeviceInfo");
  deviceInfo.value.name = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.deviceName
  );
  deviceInfo.value.firmwareVersion = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.firmwareVersion
  );
  deviceInfo.value.hardwareVersion = await bleDevice.readString(
    BLE_UUIDS.deviceService.uuid,
    BLE_UUIDS.deviceService.characteristics.hardwareVersion
  );
  console.log("deviceInfo", deviceInfo.value);
  await readSensorHeadInformation();

  batteryLevel.value = await bleDevice.readDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8"
  );
  console.log("batteryLevel", batteryLevel.value);

  powerStatus.value.batteryLevel = batteryLevel.value;

  gain.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.gain,
    "uint8"
  );
  console.log("gain", gain.value);
  exposureTime.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.exposureTime,
    "uint16"
  );
  console.log("exposureTime", exposureTime.value);
  ledCurrent.value = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.ledCurrent,
    "uint8"
  );
}

async function readSensorHeadInformation() {
  deviceInfo.value.sensorHead = undefined;

  const isSensorConnected = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.connected,
    "uint8"
  );
  if (!isSensorConnected) {
    console.log("No sensor connected, Mind your own business.");
    return;
  }

  deviceInfo.value.sensorHead = {};
  deviceInfo.value.sensorHead.serial = await bleDevice.readString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensorSerial
  );
  deviceInfo.value.sensorHead.type = await bleDevice.readDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensortype,
    "uint8"
  );

  const sensorConfig = await bleDevice.readString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.sensorConfiguration
  );
  try {
    const config = JSON.parse(sensorConfig);
    deviceInfo.value.sensorHead.name = config.name;
    deviceInfo.value.sensorHead.sensor = config.sensor;
    deviceInfo.value.sensorHead.driver = config.driver;
    deviceInfo.value.sensorHead.type = config.type;
  } catch (error) {
    console.error("Error parsing sensor config", error, sensorConfig);
  }

  const additionalInfo = await bleDevice.readString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.additionalInfo
  );
  try {
    deviceInfo.value.sensorHead.additionalInfo = JSON.parse(additionalInfo);
  } catch (error) {
    deviceInfo.value.sensorHead.additionalInfo = {};
  }
}

async function subscribeToPowerInformation() {
  console.log("subscribeToPowerInformation");
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8",
    (value) => {
      powerStatus.value.batteryLevel = value;
    }
  );
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryVoltage,
    "uint16",
    (value) => {
      powerStatus.value.batteryVoltage = value;
    }
  );
  await bleDevice.subscribeDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.usbVoltage,
    "uint16",
    (value) => {
      powerStatus.value.usbVoltage = value;
    }
  );

  // Manually read battery level once
  powerStatus.value.batteryLevel = await bleDevice.readDataType(
    BLE_UUIDS.powerService.uuid,
    BLE_UUIDS.powerService.characteristics.batteryLevel,
    "uint8"
  );
}

async function subscribeToMeasurementData() {
  console.log("subscribeToMeasurementData");
  await bleDevice.subscribeJson(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.measurementData,
    (value) => {
      console.log("measurementData in callback", value);
      onMeasurementDataFromDevice(value);
    }
  );
}

function startMeasurement() {

  console.log("startMeasurement");
  if ( !calibrationMode.value && props.forceCalibration == "true" && calibrationList.value.length == 0 )
  {
    alertForceCalibration.value = true;
    return;    
  }

  if (measurementInProgress.value) {
    // Measurement still in progress, ignore
    return;
  }
  measurementInProgress.value = true;
  bleDevice.writeString(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.measurementControl,
    "start"
  );
  resetStateTimeout = setTimeout(() => {
    measurementInProgress.value = false;
    resetStateTimeout = null;
  }, getEstimatedMeasurementDuration());
}

function startCalibration() {
  console.log("startCalibration");
  // Reset display
  displayCalibration.value = null;    
  // Signal we are in calibration mode
  calibrationMode.value = true;
  // Start normal measurement
  startMeasurement();
}

function clearCalibration() {
  console.log("clearCalibration");
  if (measurementInProgress.value) {
    // Measurement still in progress, ignore
    return;
  }
  // Signal we are not calibration mode
  calibrationMode.value = false;
  // Reset list of calibration data
  calibrationList.value = [];
  // Reset list for trigger displays
  displayCalibration.value = null;
}

function setExposureTime() {
  console.log("setExposureTime", exposureTime.value);
  // We clear calibration first
  clearCalibration();
  // Write new exposure time
  bleDevice.writeDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.exposureTime,
    "uint32",
    exposureTime.value
  );
  saveMeta();
}

function setGain() {
  console.log("setGain", gain.value);
  // We clear calibration first
  clearCalibration();
  // Write new gain
  bleDevice.writeDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.gain,
    "uint8",
    gain.value
  );
  saveMeta();
}

function setLedCurrent() {
  const current = ledCurrent.value;
  console.log("setLedCurrent", current);
  // We clear calibration first
  clearCalibration();
  // Write new led current
  bleDevice.writeDataType(
    BLE_UUIDS.sensorheadService.uuid,
    BLE_UUIDS.sensorheadService.characteristics.ledCurrent,
    "uint8",
    current
  );
  saveMeta();
}

function promptUserForDisconnect() {
  const choice = confirm("Do you want to disconnect from the device?");
  if (choice) {
    disconnectFromDevice();
  }
}

function disconnectFromDevice() {
  console.log("disconnectFromDevice");
  // We clear calibration first
  clearCalibration();
  // We disconnect
  isConnected.value = false;
}

function resetAlertCalibration() {
  alertForceCalibration.value = false;
}

</script>

<style></style>
