import { defineStore } from "pinia";
import { Storage, Drivers } from '@ionic/storage';

import { useAppStateStore } from "./app";
import { useAuthStore } from "./auth";

const storeId = "cicada_file";
export const useFileStore = defineStore(storeId, () => {
  const fileStorage = new Storage( {
    name: 'cicade_file_storage',
    driverOrder: [ Drivers.IndexedDB, Drivers.LocalStorage ],
  });

  const fileInfoStore = new Storage( {
    name: 'cicade_file_info_storage',
    driverOrder: [ Drivers.IndexedDB, Drivers.LocalStorage ],
  });



  const getCurrentUserId = () => {
    return useAuthStore().user.id;
  };
  const getCurrentAppId = () => {
    return useAppStateStore().id;
  }


  const checkAccessToFile = async (fileName) => {
    const fileInfo = await getFileInfo(fileName);
    if (!fileInfo) {
      return false;
    }    
    const appId = getCurrentAppId();
    if ( fileInfo.appId && fileInfo.appId !== appId ) {
      return false;
    }
    const userId = getCurrentUserId();
    if ( fileInfo.ownerId && fileInfo.ownerId !== userId ) {
      return false;
    }
    return true;
  };

  const getFileInfo = async (fileName) => {
    return await fileInfoStore.get(fileName);
  };

  const fileExists = async (fileName) => {
    if (!fileName) {
      return false;
    }
    const fileInfo = await getFileInfo(fileName);
    if (!fileInfo) {
      return false;
    }
    return await fileStorage.keys().then( keys => keys.includes(fileName) );
  };

  async function init() {
    await fileStorage.create();    
    await fileInfoStore.create();
  }

  /**
   * @brief List all files
   * 
   * Will only list files, that are accessible by the current user
   * and the current app.
   * 
   * @returns {Promise<string[]>} List of file names that the user has access to
   */
  async function listFiles() {
    const fileNames = await fileStorage.keys();
    const filesWithAccess = [];
    for (const fileName of fileNames) {
      if (await checkAccessToFile(fileName)) {
        filesWithAccess.push(fileName);
      }
    }
    return filesWithAccess;
  }
  

  /**
   * @brief Get the content of a file by its name
   * 
   * @param {String} fileName 
   * @returns Content of the file or empty object if the file does not exist or the user does not have access to it
   */
  async function getFile(fileName) {    
    const hasAccess = await checkAccessToFile( fileName );
    if (!hasAccess) {
      console.error(`File ${fileName} not found or you don't have access to it`);
      return {};
    }
    return await fileStorage.get(fileName);
  }

  /**
   * @brief Save a file
   *  
   * If no accessRights are specified, the file will be only accessible by the current user and the current app.
   * There is no encryption or any other security mechanism in place, so make sure to not store sensitive data.
   * 
   * Accessrights can be used to specify which user or app should have access to the file. But with a reasonable
   * technical understanding, it is possible to bypass this mechanism. So don't rely on it for security.
   * 
   * For now there is no safety mechanism that prevents overwriting files. So make sure to use unique file names.
   * 
   * @param {String} fileName Name of the file. This name must be unique, so make sure you have a good naming scheme
   * @param {Object} content Content of the file
   * @param {Object} accessRights [OPTIONAL] specify by which user/app the file should be accessible
   * @param {String} accessRights.user user id that should have access to the file or undefined if everyone should have access
   * @param {String} accessRights.app  app id that should have access to the file or undefined if everyone should have access
   * 
   * @returns {Promise<void>}
   */
  async function saveFile(fileName, content, accessRights = {}) {
    const access = {
      ownerId: getCurrentUserId(),
      appId: getCurrentAppId(),
    };
    if (Object.keys(accessRights).includes('user')) {
      access.ownerId = accessRights.user;
    }
    if (Object.keys(accessRights).includes('app')) {
      access.appId = accessRights.app;
    }
    await fileInfoStore.set(fileName, access);
    await fileStorage.set(fileName, content);
  }
  
  /**
   * @brief Delete a file
   * @param {*} fileName 
   * @returns 
   */
  async function deleteFile(fileName) {
    if (! (await fileExists(fileName) )) {
      return;
    }
    await fileStorage.remove(fileName);
    await fileInfoStore.remove(fileName);
  }
  

  init();


  return { getFile, listFiles, saveFile, deleteFile, fileExists };
});