<template>
  <div :id="id"></div>
</template>

<script setup>

import { ref, onMounted, watch } from "vue";
import { v4 as uuid4 } from "uuid";


import jsonview from '@pgrabovets/json-view';

const id = ref(uuid4());
const props = defineProps(
  {
    data: {
      type: Object,
      default: () => { return {} },
    },
  }
);

let el = undefined;
function renderTree( obj ) {
  const tree = jsonview.create( obj );
  if ( el === undefined ) {
    return;
  }
  // clear the element;
  el.innerHTML = '';
  // render the tree
  jsonview.render( tree, el );
}

onMounted( () => {
  el = document.getElementById(id.value);
  renderTree( props.data );
});
watch( () => props.data, (newVal) => {
  renderTree( newVal );
});

</script>

<style>

.json-container {
  background-color: transparent !important;
  font-family: sans-serif !important;
  color: #6D6D6D !important;
}
.json-container .json-key {
    color: #6D6D6D !important;
}
</style>