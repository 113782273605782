import Test from "./TestModule.vue";
import Text from "./TextModule.vue";
import RandomDataGenerator from "./RandomDataGeneratorModule.vue";
import MessageListener from "./MessageListener.vue";
import Plot from "./PlotModule.vue";
import CirclePlot from "./CirclePlotModule.vue";
import Matrix from "./MatrixModule.vue";
import PredictionTable from "./PredictionTableModule.vue";

import Record from "./RecordModule.vue";
import Browse from "./BrowseModule.vue";
import InfoEdit from "./InfoEditorModule.vue";
import Camera from "./CameraModule.vue";
import ImageDisplay from "./ImageDisplayModule.vue";
import SpecTelligenceScan from "./SpecTelligenceScanModule.vue";
import ScanCorder from "./ScanCorderModule.vue";
import SpecTelligenceScanVisualization from "./SpecTelligenceScanVisualizationModule.vue";
import FloatingActionButton from "./FloatingActionButtonModule.vue";

import FieldSpec from "./FieldSpecModule.vue";
import Serving from "./ServingModule.vue";
import LocalServing from "./LocalServingModule.vue";
import FileExample from "./FileExampleModule.vue";

// The list below should be sorted alphabetically.
// This must be done manually (but only once).
// Whenever a new module is added, it should be added
// at the correct alphabetical position.
export default {
  Browse,
  Camera,
  CirclePlot,
  FieldSpec,
  FloatingActionButton,
  ImageDisplay,
  InfoEdit,
  LocalServing,
  Matrix,
  MessageListener,
  Plot,
  PredictionTable,
  RandomDataGenerator,
  Record,
  ScanCorder,
  Serving,
  SpecTelligenceScan,
  SpecTelligenceScanVisualization,
  Test,
  Text,
  FileExample,
};
