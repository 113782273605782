<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ props.modelTitle }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid v-if="servingInProgress">
        <ion-row>
          <ion-col>
            <ion-spinner />
          </ion-col>
        </ion-row>
        <ion-loading :is-open="servingInProgress" message="Applying model, please wait..." spinner="crescent" />
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length === 0 && !servingInProgress">
        <p>No predictions available. Waiting for data.</p>
      </ion-grid>
      <ion-grid v-if="predictionInfoList.length !== 0 && !servingInProgress">
        <p>Model Prediction:</p>
        <ion-row>
          <ion-col size="auto" v-for="(prediction, index) in predictionInfoList" :key="index">
            <div class="prediction-cell">
              <div>{{ prediction.name.replace(/_/g, ' ') }}</div>
              <div v-if="prediction.unit !== undefined">{{ prediction.unit }}</div>
              <div :style="{ color: prediction.color }">{{ prediction.score }}</div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
.prediction-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  /* Adds a border to each cell */
  min-height: 5px;
  /* Adjust height as necessary */
  text-align: center;
  /* Centers text within items */
}
</style>

<script setup>
import Web3 from "web3";
import { IonLoading, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonSpinner, IonCardTitle, IonCardContent } from "@ionic/vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { events } from "@/utils/events";

// List of Predictions
const predictionInfoList = ref([]);
// Serving in progress indicator flag
const servingInProgress = ref(false);

// Define the props for the component which are default values
const props = defineProps({
  // Default Wallet is Compolytics Demo Safe Global @ Gnosis Chain
  modelWallet: {
    type: String,
    default: "please provide wallet adress"
  },
  // Default Model NFT Token ID
  modelTokenID: {
    type: Number,
    default: 1
  },
  // Customer secret key to sign the message
  customerKey: {
    type: String,
    default: ""
  },
  // Default AWS Lambda is Frankfurt location
  neuralEngineURL: {
    type: String,
    default: "please set the neural engine URL in the props"
  },

  // What message to listen for
  modelTitle: {
    type: String,
    default: "Model Serving",
  },

  // What message to listen for
  dataMessage: {
    type: String,
    default: "data",
  },

  // What to display from the response of the model
  predictionFieldName: {
    type: String,
    default: "",
  },

  // What to display from the response of the model
  predictionDisplayName: {
    type: String,
    default: "",
  },

  // What units to show for the prediction
  predictionDisplayUnits: {
    type: String,
    default: "",
  },

  predictionMetaName: {
    type: String,
    default: "",
  },

  taskFieldName: {
    type: String,
    default: "",
  },

  // We use Safe Global like prefix for the blockchain address
  blockchainPrefix: {
    type: String,
    default: "gno:",
  },
});

onMounted(() => {
  // Setup event listener for data messages 
  events.on(props.dataMessage, onData);

  
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

function processPredictions(props, scorePayload) {

  const predictionList = []; // Assuming this is the initial setup

  let renameTable = {};
  try {
    renameTable = JSON.parse(props.predictionDisplayName);
  } catch (error) {
    renameTable = {};
  }

  let unitTable = {};
  try {
    unitTable = JSON.parse(props.predictionDisplayUnits);
  } catch (error) {
    unitTable = {};
  }

  let metaTable = {};
  try {
    metaTable = JSON.parse(props.predictionMetaName);
  } catch (error) {
    metaTable = {};
  }

  // Ensure predictionFieldName is available and a string
  if (!props || typeof props.predictionFieldName !== 'string') {
    return []; // or handle the error appropriately
  }

  // Assume we have a regression task
  let taskType = "regression";
  if (!props || typeof props.taskFieldName === 'string') {
    // if taskfield is set, we take it
    if (scorePayload && scorePayload['prediction'] && scorePayload['prediction'][props.taskFieldName])
      taskType = scorePayload['prediction'][props.taskFieldName];
  }
 
  // Split field names and trim whitespace
  const predFieldList = props.predictionFieldName.split(',').map(name => name.trim());
  
  // Process each field name and create predictions
  predFieldList.forEach(fieldName => {

    // Check if the field name is in the scorePayload
    if (scorePayload && scorePayload['prediction'] && scorePayload['prediction'][fieldName]) {
      // Extract the numeric prediction from the scoring payload
      const number = parseFloat(scorePayload['prediction'][fieldName]);
      // If the field name is in the rename table, we use the display name
      const displayFieldName = renameTable[fieldName] ? renameTable[fieldName] : fieldName;
      // If the field name is in the unit table, we use the unit name and generate a proper formating
      const displayUnitName = unitTable[fieldName] ? "[" + unitTable[fieldName] + "]" : undefined;
      // Create the prediction structure with all display information
      const prediction = {
        name: displayFieldName,
        score: number.toFixed(2),
        unit: displayUnitName,
        color: "primary" // Default color
      };

      // If we have classification task, we replace the score with the class name and get the color
      // asscoiated with the class
      if (taskType == "classification") {
        if (metaTable[fieldName] && metaTable[fieldName] && scorePayload['prediction'][metaTable[fieldName]] && scorePayload['prediction'][metaTable[fieldName]]['label']) {
          prediction.score = scorePayload['prediction'][metaTable[fieldName]]['label'][0]
          prediction.color = scorePayload['prediction'][metaTable[fieldName]]['color'][0]
          // If the color string is to short, fill with 0
          if (prediction.color.length < 7) {
            prediction.color = prediction.color + "0".repeat(7 - prediction.color.length);
          }
        }
      }  

      // Add the prediction to the list
      predictionList.push(prediction);
    }
  });

  return predictionList;
}

async function onData(data) {

  // Set serving flag as working
  servingInProgress.value = true;
  // Clear the prediction list
  predictionInfoList.value = [];
  // Setup web3
  const web3 = new Web3();

  // Hack, we need to remove field calibration if it is any empty list
  // -----------------------------------------------------------------
  // This should be done in the model, but older version are deployed with not ignoring calibration when empty
  if (data.calibration && data.calibration.length == 0) {
    delete data.calibration;
  }

  // Lets turn this into a json request
  const inputData = JSON.stringify(data);
  // If calibration does not exist, add it back as empty
  if (!data.calibration) {
    data.calibration = [];
  }
  
  try
  {
    // We need to buld the proper request for the neural engine
    const eventPayload = {};
    eventPayload['model'] = {};
    eventPayload['model']['model_wallet'] = removePrefixSubstring(props.modelWallet, props.blockchainPrefix);
    eventPayload['model']['score_wallet'] = removePrefixSubstring(props.modelWallet, props.blockchainPrefix);
    // The token id is the unique model identifier, token id is set in secrets.json
    eventPayload['model']['token_id'] = props.modelTokenID;
    // Input payload is sensor reading json as string
    eventPayload['input'] = inputData;
    // We sign the message using the private key and recover the signature
    const secretKey = addPrefixSubstring(removePrefixSubstring(props.customerKey, props.blockchainPrefix), "0x");
    const signedMessage = web3.eth.accounts.sign(inputData, secretKey);
    // Store signature
    eventPayload['model']['signature'] = signedMessage.signature;
    console.log("Event Payload", JSON.stringify(eventPayload));
    // Lets send the request to the neural engine
    const response = await fetch(props.neuralEngineURL, {
      method: "POST",
      body: JSON.stringify(eventPayload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    console.log("Reponse: ", response);
    // Lets check the response status
    if (response.status == 200) {
      // We have a valid response
      const scorePayload = await response.json();

      console.log("Response", scorePayload);
      if (scorePayload['prediction'] == 'ERROR') {
        // If the prediction is an error, we will display the error message
        const message = {
          name: "serving error",
          score: JSON.stringify(scorePayload['info'])
        };
        predictionInfoList.value.push(message);
      } else {
        // if we a valid prediction list, lets process the model output
        predictionInfoList.value = processPredictions(props, scorePayload);
        // we save prediction and model for later use
        data.prediction = {};
        data.prediction.tokenid = props.modelTokenID;
        data.prediction.values = scorePayload['prediction'];
        events.emit("prediction", data);
      }
    } else {
      // We have an error with the request  
      console.log('Request was not successfully')
      const scorePayload = await response.json();
      const message = {
        name: "request error: ",
        score: JSON.stringify(scorePayload)
      };
      predictionInfoList.value.push(message);
    }
  } catch (error) {
    // We catch any general error
    console.log("Error: ", error);
    const message = {
      name: "Connection Error.",
      score: "Cannot reach cloud server."
    };
    predictionInfoList.value.push(message);
  }
  servingInProgress.value = false;
}

// Helper function to remove prefix from the string
function removePrefixSubstring(inputString, prefix) {
  if (inputString.startsWith(prefix)) {
    return inputString.substring(prefix.length);
  }
  return inputString;
}

function addPrefixSubstring(inputString, prefix) {
  if (inputString.startsWith(prefix)) {
    return inputString;
  }
  return prefix + inputString;
}

</script>

<style></style>