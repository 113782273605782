import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

const dataDirectory = 'data';
const appDirectory = 'cicada-2';

export const createDataDirectory = async () => {
  const directoryName = `${appDirectory}/${dataDirectory}`;
  const { uri } = await Filesystem.getUri({
    directory: Directory.Data,
    path: directoryName,
  });
  let exists = false;
  try { 
    exists = await Filesystem.exists({ path: uri });
  } catch (e) {
    console.log(e);
    exists = false;
  }
  if (!exists) {
    try {
      await Filesystem.mkdir({
        path: directoryName,
        directory: Directory.Data,
        recursive: true,
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const readDataFile = async (fileName) => {
  const directoryName = `${appDirectory}/${dataDirectory}`;
  const { data } = await Filesystem.readFile({
    path: `${directoryName}/${fileName}`,
    directory: Directory.Data,
    encoding: Encoding.UTF8,
  });
  return data;
};

export const writeDataFile = async (fileName, data) => {
  await createDataDirectory();
  const directoryName = `${appDirectory}/${dataDirectory}`;
  await Filesystem.writeFile({
    path: `${directoryName}/${fileName}`,
    data: data,
    directory: Directory.Data,
    encoding: Encoding.UTF8,
  });
};


export const deleteDataFile = async (fileName) => {
  const directoryName = `${appDirectory}/${dataDirectory}`;
  await Filesystem.deleteFile({
    path: `${directoryName}/${fileName}`,
    directory: Directory.Data,
  });
};

export const getDataFiles = async () => {
  const directoryName = `${appDirectory}/${dataDirectory}`;
  try {
  const { files } = await Filesystem.readdir({
    path: directoryName,
    directory: Directory.Data,
  });
  return files;
  } catch (e) {
    console.error(e);
    return [];
  }
};


export { Filesystem, Directory, Encoding, dataDirectory, appDirectory };