<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        Message Listener
      </ion-card-title>
    </ion-card-header>
    
    <ion-card-content> 
      <ion-grid>
        <ion-row v-for="(count, message) in counter" :key="message">
          <ion-col>{{message}}</ion-col>
          <ion-col>{{ count }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            Last Message: [{{ lastMessageType }}]
          </ion-col>
          <ion-col>
            <data-tree :data="lastMessage"> </data-tree> 
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonCard, IonCardTitle, IonCardHeader } from '@ionic/vue';
import { onMounted, onBeforeUnmount, ref } from "vue";
import { events } from '@/utils/events';
import DataTree from "@/components/DataTree.vue";

const counter = ref({});
const lastMessage = ref({ a:1, b:2});
const lastMessageType = ref("none");


function logMessage( type, e ) {
  console.log( `Message [${type}]:`, e );
}

function countMessage( type ) {
  if ( !Object.keys( counter.value ).includes( type ) ) {
    counter.value[type] = 0;
  }
  counter.value[type]++;
}

function onMessage( type, e ) {
  logMessage( type, e );
  countMessage( type );
  
  lastMessage.value = e;
  lastMessageType.value = type;
}

onMounted(() => {
  console.log("Component is mounted");
  console.log("Register handler");
  events.on( '*', onMessage );
});

onBeforeUnmount(() => {
  events.off( '*', onMessage );
  console.log("component was unmounted.");
});
</script>

<style></style>
