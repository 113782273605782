export function randomColor() {
  return '#' + Math.random().toString(16).slice(2, 8);
}

export function randomColorArray( length ) {
  return Array.from(Array(length)).map( randomColor );
}

export function colorFromWavelength( wl ) {
  // https://academo.org/demos/wavelength-to-colour-relationship/

  let R, G, B;

  if ( wl < 380 ) {
    R = 1;
    G = 0;
    B = 1;
  } else if (wl >= 380 && wl < 440) {
      R = -1 * (wl - 440) / (440 - 380);
      G = 0;
      B = 1;
  } else if (wl >= 440 && wl < 490) {
      R = 0;
      G = (wl - 440) / (490 - 440);
      B = 1;
  } else if (wl >= 490 && wl < 510) {
      R = 0;
      G = 1;
      B = -1 * (wl - 510) / (510 - 490);
  } else if (wl >= 510 && wl < 580) {
      R = (wl - 510) / (580 - 510);
      G = 1;
      B = 0;
  } else if (wl >= 580 && wl < 645) {
      R = 1;
      G = -1 * (wl - 645) / (645 - 580);
      B = 0.0;
  } else if (wl >= 645 && wl <= 780) {
      R = 1;
      G = 0;
      B = 0;
  } else {
      R = 1;
      G = 0;
      B = 0;
  }

  let alpha;
  // intensty is lower at the edges of the visible spectrum.
  if (wl > 780 || wl < 380) {
      alpha = 0;
  } else if (wl > 700) {
      alpha = (780 - wl) / (780 - 700);
  } else if (wl < 420) {
      alpha = (wl - 380) / (420 - 380);
  } else {
      alpha = 1;
  }

  // Let the intensity fall off near the vision limits
  // There is a minimum intensity level, so that non-visible
  // colors are shown as very dim colors, rather than not at all.
  const intensity = (0.2 + 0.8*alpha) * 255
  const colorSpace = [Math.floor(R * intensity), Math.floor(G * intensity), Math.floor(B * intensity)];

  // Map to html color code
  return '#' + colorSpace.map( function( x ) {
    return ("0" + Math.round( x ).toString(16)).slice(-2);
  }).join('').substring(0, 6); 
}