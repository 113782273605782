<template>
  <ion-accordion-group class="ion-margin-bottom">
    <ion-accordion>
      <ion-item slot="header" class="ion-padding-left">
        <ion-item lines="none" class="ion-no-padding">
          <ion-button slot="start" fill="clear" @click="$emit('removeMe')">
            <ion-icon class="bi bi-trash" />
          </ion-button>
        </ion-item>
        <ion-item lines="none" class="ion-no-padding">
          <ion-label position="floating">type</ion-label>
          <ion-select
            interface="action-sheet"
            :value="modelValue.type"
            @IonChange="
              $emit('update:modelValue', {
                ...modelValue,
                type: $event.target.value,
              })
            "
          >
            <ion-select-option
              v-for="(moduleObj, typeKey) of Modules"
              :key="typeKey"
              :value="typeKey"
            >
              {{ typeKey }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none" class="ion-no-padding">
          <ion-label position="floating">Module name</ion-label>
          <ion-input
            :size=10
            :value="modelValue.name"
            @input="
              $emit('update:modelValue', {
                ...modelValue,
                name: $event.target.value,
              })
            "
            placeholder="A module"
            class="ion-no-padding"
          >
          </ion-input>
        </ion-item>
      </ion-item>

      <ion-list slot="content">
        <ion-item
          v-for="(option, optionKey) of Modules[modelValue.type]?.props || {}"
          :key="optionKey"
        >
          <ion-label position="floating">{{ optionKey }} {{ option['choice'] }}</ion-label>
          <ion-select
            v-if="option['choice']"
            interface="action-sheet"
            :value="(modelValue?.options
                ? modelValue?.options[optionKey]
                : undefined) ||
              (typeof option.default === 'function'
                ? option.default()
                : option.default) ||
              ''"
            @IonChange="
              $emit('update:modelValue', {
                ...modelValue,
                options: {
                  ...modelValue.options,
                  [optionKey]: $event.target.value,
                },
              })
            "
          >
            <ion-select-option
              v-for="choice in option['choice']"
              :key="choice"
              :value="choice"
            >
              {{ choice }}
            </ion-select-option>
          </ion-select>
          <ion-input
            v-else
            @input="optionInput(optionKey, $event.target.value)"
            :value="
              (modelValue?.options
                ? modelValue?.options[optionKey]
                : undefined) ||
              (typeof option.default === 'function'
                ? option.default()
                : option.default) ||
              ''
            "
            :placeholder="optionKey == 'colors'
               ? 'by number of rows e.g. #000000,#960018,...'
               :''"
            class="ion-no-padding"
          >
          </ion-input>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import {
  IonAccordion,
  IonAccordionGroup,
  IonList,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import Modules from "@/modules";

const props = defineProps({
  modelValue: {
    type: Object,
    default() {
      return {
        name: undefined,
        type: undefined,
        options: {},
      };
    },
  },
});
const emit = defineEmits(["update:modelValue", "removeMe"]);

function emitChanges() {
  // console.log("handleChange,", {
  //   name: moduleName.value,
  //   type: moduleType.value,
  //   options: moduleOptions.value,
  // });
  // emit('update:modelValue', {
  //   name: moduleName.value,
  //   type: moduleType.value,
  //   options: moduleOptions.value,
  // });
}

function optionInput(key, value) {
  const moduleOptions = props.modelValue?.options || {};
  if (value) {
    if ( !Number.isNaN(Number.parseFloat(value))) {
      value = parseFloat( value );
    }
    moduleOptions[key] = value;
    const prop = Modules[props.modelValue.type]?.props[key];
    if (prop) {
      if (typeof prop.default === "function" && prop.default() == value)
        delete moduleOptions[key];
    }
    console.log(value, prop.default, value == prop.default);
    if (prop.default == value) {
      delete moduleOptions[key];
    }
  } else {
    delete moduleOptions[key];
  }
  emit("update:modelValue", {
    ...props.modelValue,
    options: moduleOptions,
  });
  emitChanges();
}
</script>

<style scoped>
.moduleEditHeader {
  display: flex;
  justify-content: space-between;
}
.moduleEditHeader > ion-item {
  flex-grow: 1;
}
</style>
