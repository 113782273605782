<template>
  <ion-page>
    <ion-row class="ion-justify-content-center">
      <ion-col size="12" size-sm="8">
        <ion-card>
          <ion-card-header color="primary">
            <ion-card-title> Reset Password </ion-card-title>
          </ion-card-header>
          <form @submit.prevent="onForgotPassword" v-if="!emailSent">
            <ion-card-content>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <ion-item lines="full">
                    <ion-label position="floating">Email</ion-label>
                    <ion-input type="email" required v-model="email" />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col style="text-align:center;" size="12" size-sm="4">
                  <ion-button type="submit" color="primary" expand="block">
                    Reset Password
                  </ion-button>
                  <a href="login" color="primary">Back to Login</a>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </form>

          <ion-card-content v-else>
            <br />
            <h2>A Password recovery link was sent to your email.</h2>
            <br />
            <h2>{{ emailSentInfo }}</h2>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonLabel,
  IonInput,
  alertController,
} from "@ionic/vue";

import { 
  getAbsoluteLink, 
  mailWasSent, 
  sendingMailMustWait, 
  timeLimitForSendingMails,
  calculateTimestampDifference 
} from "@/utils";

import { supabase } from "@/supabaseClient";
import { ref } from "vue";
import { mailSentStore } from "@/store/mail.js";

const email = ref("");
const emailSent = ref(false);
const emailSentInfo = mailWasSent();
const mail = mailSentStore();
const oldTimestamp = ref(undefined);

async function onForgotPassword() {
  const currentTimestamp = Date.now();
  oldTimestamp.value = mail.sendMail[email.value];

  const timestampDiff = calculateTimestampDifference(oldTimestamp.value, currentTimestamp);
  const limit = timeLimitForSendingMails();
  const remainTime = limit - timestampDiff;

  if (timestampDiff < limit) {
    const successMessage = await alertController.create({
      header: "Mail could not be sent",
      message: sendingMailMustWait(remainTime),
      buttons: ["OK"],
    });
    await successMessage.present();
  } else {
    const response = await supabase.auth.resetPasswordForEmail(email.value, {
      redirectTo: getAbsoluteLink("/resetPassword"),
    });
    console.log(response);
    
    if (!response.error) {
      mail.setTimestampByMail(email.value, currentTimestamp);
      emailSent.value = true;
    }
  }
}

</script>
