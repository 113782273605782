<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> Plot </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div id="MeasurementDataChart">
        <BarChart
          v-if="chartData.datasets.length!=0"
          id="barChart"
          :chartData="chartData"
          :options="{
            scales: {
              y: {
                title: {
                  text: yAxisTitle,
                  display: true,
                },
              },
              x: {
                title: {
                  text: xAxisTitle,
                  display: true,
                },
              },
            },
            plugins: {
              legend: {
                display: legend,
              },
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    yMin: props.upperThreshold,
                    yMax: props.upperThreshold,
                    borderColor: 'grey',
                    borderWidth: 2,
                    borderDash:[2,2]
                  },

                  line2: {
                    type: 'line',
                    yMin: props.lowerThreshold,
                    yMax: props.lowerThreshold,
                    borderColor: 'grey',
                    borderWidth: 2,
                    borderDash:[2,2]
                  }
                }
              }
            },
          }"
        />
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
} from "@ionic/vue";

import { BarChart } from "vue-chart-3";
import { onMounted, onBeforeUnmount, ref } from "vue";
import { events } from "@/utils/events";
import { straightIntArray } from "@/utils/math";
import { Chart, registerables } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables, annotationPlugin);
Chart.defaults.backgroundColor = "#71814E";

const chartData = ref({
  labels: [],
  datasets: [],
});

const props = defineProps({
  labels: {
    type: Array,
    default: () => {
      return [];
    },
  },
  goodColor: {
    type: String,
    default: "#71814E",
  },
  badColor: {
    type: String,
    default: "#821a1a",
  },
  threshold: {
    type: Number,
    default: 65000,
  },
  upperThreshold:{
    type: Number,
    default: 800,
  },
  lowerThreshold:{
    type: Number,
    default: 200,
  },
  yAxisTitle: {
    type: String,
    default: "value",
  },
  xAxisTitle: {
    type: String,
    default: "",
  },
  legend: {
    type: Boolean,
    default: false,
  },
});

function updateChart(dataMessage) {
  if (!dataMessage.values) {
    return;
  }
  const newChartData = {
    labels:
      dataMessage.meta?.xLabel?.length > 0
        ? dataMessage.meta.xLabel
        : straightIntArray(dataMessage.shape[1]),
    datasets: [],
  };

  for (const dataValues of dataMessage.values) {
    const colors = [];
    for (const value of dataValues) {
      colors.push(value >= props.threshold ? props.badColor : props.goodColor);
    }
    newChartData.datasets.push({
      data: dataValues,
      backgroundColor: colors,
    });
  }
  console.log(newChartData);
  chartData.value = newChartData;
}

onMounted(() => {
  events.on("data", updateChart);
});

onBeforeUnmount(() => {
  events.off("data", updateChart);
});
</script>

<style></style>
