<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Camera</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="cameraPreview"></div>
      <video id="cameraLivePreview" autoplay></video>
    </ion-card-content>
    <!-- <ion-button fill="outline" @click="startCamera">Start</ion-button> -->
    <ion-button fill="outline" @click="takeSnapshot">Snapshot</ion-button>
    <!-- <ion-button fill="outline" @click="stopCamera">Stop</ion-button> -->
  </ion-card>
</template>

<script setup>

import { onMounted, onBeforeUnmount } from "vue";
import { events } from "@/utils/events";
import { dataMessage } from "@/messages/dataMessage";

onMounted( () => {
  startCamera();
} );
onBeforeUnmount( () => {
  stopCamera();
} );

let cameraStream = undefined;

function startCamera() {
  navigator.getUserMedia(
    {
      video: { width: 320, height: 240 },
      audio: false,
    },
    function (stream) {
      console.log("getUserMedia() got stream: ", stream);
      cameraStream = stream;
      const video = document.getElementById("cameraLivePreview");
      video.srcObject = stream;
    },
    function (error) {
      console.log("getUserMedia error: ", error);
    }
  );
}

function takeSnapshot() {
  const video = document.getElementById("cameraLivePreview");
  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext("2d").drawImage(video, 0, 0);
  const image = canvas.toDataURL("image/png");
  console.log(image);
  const data = dataMessage();
  data.type = "image";
  data.source = "CameraModule";
  data.shape = [canvas.height, canvas.width];
  data.values = image;
  data.config = {};

  events.emit('data', data);
}

function stopCamera() {
  if (!cameraStream) return;
  cameraStream.getTracks().forEach((track) => track.stop());
}
</script>

<style scoped>
#cameraLivePreview {
  width: 100%;
  height: 100%;
}
</style>
