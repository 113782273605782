<template>
  <ion-item class="fl-entry">
    <ion-chip slot="start" color="none">
      <ion-checkbox :checked="props.checked" @ionChange="checkedChange" />
    </ion-chip>
    <ion-text>
      {{ props.filename }}_{{ appIdToName(props.appId) }}_{{
        userIdToName(props.userId)
      }}
      <br />
      <div class="fileDate">
        created at: {{ new Date(props.createdAt).toLocaleString() }}
      </div>
      <ion-button
        v-if="!fileString"
        :disabled="isLoading"
        size="small"
        @click="fetchData(props.uuid)"
      >
        <ion-spinner v-if="isLoading" name="dots"></ion-spinner>
        <template v-else> Load </template>
      </ion-button>
      <data-tree v-if="fileString != undefined" :data="fileString"> </data-tree>
      <br />
    </ion-text>
  </ion-item>
  <br />
</template>

<script setup>
import { ref, onMounted } from "vue";
import DataTree from "@/components/DataTree.vue";
import { IonItem, IonChip, IonCheckbox, IonText , IonSpinner} from "@ionic/vue";
import { supabase } from "@/supabaseClient";
import { IonButton } from "@ionic/vue";

const fileString = ref(undefined);
const isLoading = ref(false);

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  uuid: {
    type: String,
    default: "",
  },
  checked: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
  users: {
    type: Object,
    default: () => {
      return {};
    },
  },
  userId: {
    type: String,
    default: "",
  },
  apps: {
    type: Object,
    default: () => {
      return {};
    },
  },
  appId: {
    type: String,
    default: "",
  },
  createdAt: {
    type: String,
    default: "",
  },
  index: {
    type: Number,
    default: 0,
  },
  filename: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["selected", "unselected"]);

function appIdToName(appId) {
  console.log("looking for name of ", appId);
  console.log(props.apps);
  const app = props.apps.find((app) => app.id === appId);
  if (app) {
    return app.name;
  } else {
    return appId;
  }
}

function userIdToName(userId) {
  console.log("looking for email of ", userId);
  console.log(" props.users", props.users);
  const user = props.users.find((user) => user.id === userId);
  if (user) {
    return user.email;
  } else {
    return userId;
  }
}

onMounted(() => {
  console.log("FileListEntry onMounted", props);
  // name.value = props.name;
  // uuid.value = props.uuid;
  // checked.value = props.checked;
  // data.value = props.data;
  // createdAt.value = props.createdAt;
  // filename.value = props.filename;
});

function checkedChange($event) {
  if ($event.detail.checked) {
    emit("selected", props.uuid);
  } else {
    emit("unselected", props.uuid);
  }
}

async function fetchData(uuid) {
  isLoading.value = true;
  const res = await supabase
    .from("datafiles")
    .select("filestring")
    .eq("filestring->>uuid", uuid);

  if (res.error) {
    console.log("error", res.error);
  } else {
    fileString.value = res.data[0].filestring;
  }
  isLoading.value = false;
}
</script>

<style scoped>
.fl-entry {
  align-items: flex-start;
}
.fileDate {
  font-size: 14px;
  color: var(--ion-color-medium, #92949c);
}
</style>
