<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> RDG </ion-card-title>
      <ion-card-subtitle> Random Data Generator </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content> </ion-card-content>
    <ion-button fill="clear" @click="generateData">Generate</ion-button>
  </ion-card>
</template>

<script setup>
import { randomInt } from "@/utils/math";
import { events } from "@/utils/events";
import { randomColorArray } from "@/utils/color";
import { dataMessage } from "@/messages/dataMessage";

const props = defineProps({
  rows: {
    type: Number,
    default() {
      return 4;
    },
  },
  cols: {
    type: Number,
    default() {
      return 3;
    },
  },
  lowerBound: {
    type: Number,
    default() {
      return 0;
    },
  },
  upperBound: {
    type: Number,
    default() {
      return 1024;
    },
  },
});

function generateData() {
  const data = dataMessage();
  data.source = "RandomDataGeneratorModule";
  data.shape = [props.rows, props.cols];
  data.type = "raw";
  data.config = {
    upperBound: props.upperBound,
    lowerBound: props.lowerBound,
  };
  data.meta = {
    xColor: randomColorArray(props.rows),
    // xColor: [ 
    //   colorFromWavelength(350), colorFromWavelength(400), 
    //   colorFromWavelength(450), colorFromWavelength(500), 
    //   colorFromWavelength(550), colorFromWavelength(600), 
    //   colorFromWavelength(650), colorFromWavelength(700), 
    //   colorFromWavelength(750), colorFromWavelength(800), ],
  };
  
  data.values = [];  
  for (let rowIndex = 0; rowIndex < props.rows; rowIndex++) {
    const row = [];
    for (let colIndex = 0; colIndex < props.cols; colIndex++) {
      row.push(randomInt(props.upperBound, props.lowerBound));
    }
    data.values.push(row);
  }
  events.emit('data', data);
  console.log("From number generate", data)
}
</script>

<style></style>
