<template>
    <ion-card>
    <ion-card-header>
      <ion-card-title> Circle Plot </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div
          style="display: flex; justify-content: center; overflow:hidden"
          v-if="isRangeAdjustable"
        >
          <ion-range
            v-model="maxValuePower"
            :min="0"
            :max="16"
            step="1"
            :ticks="true"
            :snaps="true"
            :pin-formatter="pinFormatter"
            @ionChange="onRangeChange"
          />
          <span class="ms-2 maxValueIndicator">
            {{ maxValue }}
          </span>
        </div>

      <div v-if="isChartDrawable">
        <ion-text v-if="isChartsProcessed" style="font-weight:bold;">
          {{ props.receiver1 }}
        </ion-text>
          <DoughnutChart 
            id="donutChart" 
            :chartData="chartData_receiver1" 
            :options="chartOptions_receiver1" 
          />
          <br />
          <br />
          <ion-text v-if="isChartsProcessed" style="font-weight:bold;">
            {{ props.receiver2 }}
          </ion-text>
          <DoughnutChart 
            id="donutChart2" 
            :chartData="chartData_receiver2" 
            :options="chartOptions_receiver2" 
          />
      </div>
      <div v-if="!isChartDrawable">
        <ion-icon 
          color="danger" 
          style="font-size: 24px;" 
          :icon="warningOutline" 
        />
        Circle Plot could not be created. 
        Only 2 columns and 12 rows must be defined in matrix.
      </div>
    </ion-card-content>
    </ion-card>
</template>
<script setup>
import {
  IonIcon,
  IonRange
} from "@ionic/vue";
import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { events } from "@/utils/events";
import { isDarkMode } from "@/utils/index";
import interpolate from "color-interpolate";
import {warningOutline} from "ionicons/icons";

Chart.register(...registerables, ChartDataLabels);

const realLabelData_receiver1 = [];
const realLabelData_receiver2 = [];
const isChartsProcessed = ref(false);
const isChartDrawable = ref(true);
const chartOptions_receiver1 = getChartOptions(realLabelData_receiver1);
const chartOptions_receiver2 = getChartOptions(realLabelData_receiver2);
const lastDataMessage = ref(undefined);
const receiver_rows = 12;
const receiver_cols = 2;

const chartData_receiver1 = ref({
  labels: [],
  datasets: [],
});

const chartData_receiver2 = ref({
  labels: [],
  datasets: [],
});

const props = defineProps({
  receiver1: {
    type: String,
    default: "Receiver #1",
  },
  receiver2: {
    type: String,
    default: "Receiver #2",
  },
  adjustableRange: {
    type: String,
    default: "true",
  },
  fixedRange: {
    type: Number,
    default: 12,
  },
});

const maxValuePower = ref(props.fixedRange);
console.log("maxValuePower", maxValuePower.value);
const maxValue = computed(() => {
  return Math.pow(2, maxValuePower.value);
});

const isRangeAdjustable = computed(() => {
  return props.adjustableRange === "true";
});

function onRangeChange() {
  updateDonutChart(lastDataMessage.value);
}

function pinFormatter(value) {
  return value.toString();
}

function getColorByColorMode(value){
  if(value == "matrixBackground"){
   return isDarkMode() ? "#000000" : "#ffffff";
  }else if(value == "circleBorder"){
   return isDarkMode() ? "#1e1d1f" : "#f3f3f3";
  }
  return isDarkMode() ? 'white' : 'black';
}

function getChartOptions(realLabelData_receiver1){
    const options = {
      responsive: true,  
      radius:'95%',
      cutout:'20%',
      plugins: {
          datalabels: {
            rotation: function(context) {
              const segmentValues = context.dataset.data.slice(0, context.dataIndex).reduce((a, b) => a + b, 0);
              const sum = context.dataset.data.reduce((a, b) => a + b, 0);
              const rotation = ((segmentValues + context.dataset.data[context.dataIndex] /2) /sum * 360);
              return rotation < 180 ? rotation-180 : rotation+180;
          },
            labels: {
              value: {
                align: 'center',
                anchor: 'center',
                color: function(context) {
                  const color1 = isDarkMode() ? '#ffffff' : '#000000';
                  const color2 = isDarkMode() ? '#000000' : '#ffffff';
                  const colormap = interpolate([color1, color2]);
                  return colormap( realLabelData_receiver1[context.datasetIndex][context.dataIndex]/maxValue.value);
                },
                formatter: function(value, context) {
                  return realLabelData_receiver1[context.datasetIndex][context.dataIndex];
                },
              }
            }
          },
          tooltip: {
            enabled: false
          },
      }
    }
    return options;
}

function updateDonutChart(dataMessage) { 
  if (dataMessage.shape[0] != receiver_rows ||
    dataMessage.shape[1] != receiver_cols) {
    isChartDrawable.value = false;
  }

  if (!dataMessage.values) {
    return;
  }

  isChartsProcessed.value = true;

  const newChartData1 = {
    labels:[],
    datasets: [],
  };

  const newChartData2 = {
    labels:[],
    datasets: [],
  };
  
  const calulatedColors_receiver1 = calculateColor(dataMessage,0);
  const calulatedColors_receiver2 = calculateColor(dataMessage,1);

  let colorIndex = 0;
  const segmentSizeValues = calculateSegmentSize(dataMessage);

  for (const segmentValues of segmentSizeValues) { 
    setChartData(newChartData1, segmentValues, calulatedColors_receiver1[colorIndex] );
    setChartData(newChartData2, segmentValues, calulatedColors_receiver2[colorIndex] );
    colorIndex++;
  }
 
  //build data struktur for correct label displaying in segments
  buildDataStructure(dataMessage, 0,  realLabelData_receiver1); 
  buildDataStructure(dataMessage, 1,  realLabelData_receiver2); 

  lastDataMessage.value = dataMessage;
  chartData_receiver1.value = newChartData1;
  chartData_receiver2.value = newChartData2;
}


function setChartData(chartData, segmentVals, colorsReceiver ){
    chartData.datasets.push({
      data: segmentVals,
      backgroundColor: colorsReceiver,
      borderColor: getColorByColorMode("circleBorder"),
      borderWidth:5,
      hoverBorderWidth:0,
    });
}

function buildDataStructure(dataMessage, column, buildData){
  let index = 0;
  let temp = [];

  for (const dataValues of dataMessage.values) { 
    temp.push(dataValues[column])
    index++;

    if(index >2){
      buildData.push(temp)
      index = 0;
      temp = [];
    }
  }
}


onMounted(() => {
  events.on("data", updateDonutChart);
});

onBeforeUnmount(() => {
  events.off("data", updateDonutChart);
});


function calculateSegmentSize(_dataMessage){
  const segments = [];
  const numbersOfSegments = 3;
  const numberOfCircles = 4;
 
  for (let k = 0; k<numberOfCircles; k++) {
    const temp = [];
    for(let l = 0; l<numbersOfSegments; l++){
      temp.push(1 / numbersOfSegments);
    }
    segments.push(temp);
  }
  
  return segments;
}

function calculateColor(dataMessage, column) {
  const newColors = [];
  const colors = [];
  const circleData = [];

  let i = 0;
  let tempColors = [];
  for (const dataColor of dataMessage.meta.xColor) { 
    
    tempColors.push(dataColor)
    i++;

    if(i >2){
      colors.push(tempColors)
      i = 0;
      tempColors = [];
    }
    
  }
 
  buildDataStructure(dataMessage, column, circleData); 
 
  for(let l = 0; l< circleData.length; l++){
    const temp = [];
    for(let p = 0; p < circleData[l].length; p++ ){
       const rawColor = colors[l][p];

       const targetColor = rawColor
          ? rawColor
          : isDarkMode()
          ? "white"
          : "black";

       const colormap = interpolate([getColorByColorMode("matrixBackground"), targetColor]);
       const  newColor = colormap(circleData[l][p] / maxValue.value);
       temp.push( newColor );
    }

    newColors.push(temp);
  }
  
  return newColors;
}

</script>