<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> CICADA </ion-card-title>
              <ion-card-subtitle>
                Compolytics Integrated Control Application for Devices and Analysis
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              © <span>{{ currentYear }}</span> Compolytics, 
              <a href="https://compolytics.com/" target="_blank">
                www.compolytics.com
              </a>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonCol, IonRow } from "@ionic/vue";

const currentYear = new Date().getFullYear();
</script>

<style></style>
