import { defineStore } from "pinia";
import { ref } from "vue";

const storeId = "meta";

export const useMetaStore = defineStore( storeId, () => {
  const meta = ref( {} );

  function set( key, value ) {
    meta.value[ key ] = value;
  }
  function get( key ) {
    return meta.value[ key ];
  }
  function remove( key ) {
    if ( meta.value[ key ] ) {
      delete meta.value[ key ];
    }
  }
  function clear() {
    meta.value = {};
  }
  return { meta, set, clear, get, remove };
});
