import { defineStore } from "pinia";
import { ref } from "vue";


const storeId = "auth";
export const useAuthStore = defineStore(storeId, () => {
  const user = ref(undefined);
  function setLoggedIn( newUser ) {
    user.value = newUser;
  }
  function isLoggedIn() {
    return !!(user?.value);
  }

  return { user, setLoggedIn, isLoggedIn };
},
{persist: true },);
