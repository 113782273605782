<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-title class="ion-padding">
              <h2>Welcome to CICADA.</h2>
            </ion-card-title>
            <ion-card-content>
              The following short explanation about each menu item 
              shall give you an overview of the CICADA app.
              <br />
              Each item is represented by a different clickable icon
               in the footer of the app.
              <br />
              <ion-item lines="none" color="#f0f0f0d1;">
                <ion-icon slot="start" class="bi bi-house-door" />
                <ion-text>Home view (current view).</ion-text>
              </ion-item>
              <ion-item lines="none" color="#f0f0f0d1;">
                <ion-icon slot="start" class="bi bi-play" />
                <ion-text>Selection of different executable apps.</ion-text>
              </ion-item>
              <ion-item lines="none" color="#f0f0f0d1;">
                <ion-icon slot="start" class="bi bi-info-circle" />
                <ion-text>General information about CICADA app.</ion-text>
              </ion-item>
              <ion-item 
                v-if="auth.user.admin" 
                lines="none" 
                color="#f0f0f0d1;"
              >
                <ion-icon slot="start" class="bi bi-person-gear" />
                <ion-text>
                  Only for admins: Section for creating, editing 
                  and deleting apps, inviting and deleting users.
                </ion-text>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import {
  IonText,
  IonCol,
  IonRow
} from "@ionic/vue";

const auth = useAuthStore();
</script>