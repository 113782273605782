<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>specTelligence Scan Visualization</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-row>
        <ion-col size="12" size-sm="8">
          <swiper
            :pagination="pagination"
            :modules="modules"
            :loop="true"
            :zoom="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="channel in channels" :key="channel.name">
              <div class="ImagePreview">
                <canvas :id="'scanImagePreview' + channel.name"></canvas>
              </div>
            </swiper-slide>
          </swiper>
        </ion-col>
        <ion-col size="12" size-sm="4">
          <div id="dataDetails"></div>
          <div id="dataPreview">
            <ion-row
              v-for="(datarow, rowIndex) in spectralFingerPrint"
              :key="'row' + rowIndex"
            >
              <ion-col
                v-for="(d, index) in datarow"
                :key="'d' + rowIndex + '-' + index"
              >
                <div
                  class="dataEntry"
                  :style="{ backgroundColor: getColor(rowIndex, index, d) }"
                >
                  <p>{{ d }}</p>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <!-- This canvas actually holds the whole image. It is easier to access it from a canvas -->
      <canvas id="scanImage"></canvas>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonRow, IonCol } from "@ionic/vue";
import { onMounted, onBeforeUnmount, ref } from "vue";
import { events } from "@/utils/events";
import { isPNGDataURL, limitNumber } from "@/utils";
import tinycolor from "tinycolor2";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";

const channels = ref([
  {
    name: "W",
    color: "#ffffff",
  },
  {
    name: "R",
    color: "#ff0000",
  },
  {
    name: "G",
    color: "#00ff00",
  },
  {
    name: "B",
    color: "#0000ff",
  },
  {
    name: "b",
    color: "#000000",
  },
  {
    name: "I",
    color: "#ffffff",
  },
]);

const props = defineProps({
  dataMessage: {
    type: String,
    default: "data",
  },
});

const modules = ref([Pagination]);

const pagination = ref({
  clickable: true,
  renderBullet: function (index, className) {
    console.log(className);
    if (className.includes("swiper-pagination-bullet-active")) {
      console.log("active", index);
    }
    const chan = channels.value[index];
    return `<span class="${className}" style="color: ${chan.color}"> ${chan.name}</span>`;
  },
});

function onSwiper(swiper) {
  console.log(swiper);
}

function onSlideChange() {
  console.log("slide change");
}

onMounted(() => {
  events.on(props.dataMessage, onData);

  // Add click event listener to canvas
  const onPreviewImageClick = (canvas) => {
    return (event) => {
      const rect = canvas.getBoundingClientRect(); // get canvas position
      // calculate mouse position relative to canvas
      const x = limitNumber(
        Math.round(event.clientX - rect.left),
        0,
        canvas.width - 1
      );
      const y = limitNumber(
        Math.round(event.clientY - rect.top),
        0,
        canvas.height - 1
      );

      console.log(`Clicked at (${x}, ${y})`);
      const data = getSpectralFingerprintForPoint(x, y);
      console.table(data);
      spectralFingerPrint.value = data;
    };
  };
  for (const channel of channels.value) {
    const canvas = document.getElementById("scanImagePreview" + channel.name);
    canvas.addEventListener("click", onPreviewImageClick(canvas));
  }
});

onBeforeUnmount(() => {
  events.off(props.dataMessage, onData);
});

let img = undefined;
const spectralFingerPrint = ref([]);

function drawSaturatedPixelMask(img, limit, canvasContext) {
  const canvas2 = document.getElementById("scanImage");
  const ctx2 = canvas2.getContext("2d");
  const saturatedPixelColor = "#ff1fe1";
  const IMAGES_PER_ROW = 3;
  const IMAGES_PER_COLUMN = 2;
  const scanWidth = img.width / IMAGES_PER_ROW;
  const scanHeight = img.height / IMAGES_PER_COLUMN;

  const pixelData = ctx2.getImageData(0, 0, img.width, img.height);
  const data = pixelData.data;
  const saturatedPixels = [];

  // Loop over image pixels
  for (let y = 0; y < scanHeight; y++) {
    for (let x = 0; x < scanWidth; x++) {
      // Loop over images to check if a pixel is saturated
      for (let channel = 0; channel < 5; channel++) {
        const pixelX = x + (channel % 3) * scanWidth;
        const pixelY = y + Math.floor(channel / 3) * scanHeight;
        const i = 4*(pixelY * img.width + pixelX);
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        // const a = data[i + 3];
        if (r > limit || g > limit || b > limit) {
          saturatedPixels.push({x,y});
          break;
        }
      }
    }
  }
  canvasContext.fillStyle = saturatedPixelColor;
  for (const pixel of saturatedPixels) {
    canvasContext.fillRect(pixel.x, pixel.y, 1, 1);
  }
}

function getSpectralFingerprintForPoint(x, y) {
  if (!img) {
    return [];
  }
  const IMAGES_PER_ROW = 3;
  const IMAGES_PER_COLUMN = 2;
  const scanWidth = img.width / IMAGES_PER_ROW;
  const scanHeight = img.height / IMAGES_PER_COLUMN;
  x = limitNumber(x, 0, scanWidth - 1);
  y = limitNumber(y, 0, scanHeight - 1);

  const spectralFingerPrint = [];
  const canvas = document.getElementById("scanImage");
  const ctx = canvas.getContext("2d");

  for (let col = 0; col < IMAGES_PER_COLUMN; col++) {
    for (let row = 0; row < IMAGES_PER_ROW; row++) {
      const xOffset = row * scanWidth;
      const yOffset = col * scanHeight;
      const pixelData = ctx.getImageData(x + xOffset, y + yOffset, 1, 1);
      const data = pixelData.data;
      //console.log(data);
      const i = 0;
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      //const a = data[i + 3];
      spectralFingerPrint.push([r, g, b]);
    }
  }
  return spectralFingerPrint;
}

async function onData(dataMessage) {
  console.log("data received");

  if (!dataMessage) return;
  if (dataMessage?.type !== "image") return;
  if (dataMessage?.source !== "SpecTelligenceScanModule") return;

  const data = dataMessage.values;
  if (!isPNGDataURL(data)) {
    console.log("data is not PNG");
    return;
  }

  img = new Image();
  img.src = data;
  img.onload = function () {
    console.log("image loaded");
    // Get image dimensions
    const width = img.width;
    const height = img.height;
    const canvas2 = document.getElementById("scanImage");
    canvas2.width = width;
    canvas2.height = height;

    const ctx2 = canvas2.getContext("2d");
    ctx2.drawImage(img, 0, 0);
    const previewWidth = width / 3;
    const previewHeight = height / 2;
    let index = 0;
    
    for (const channel of channels.value) {
      console.log("drawing channel", channel.name);
      const canvas = document.getElementById("scanImagePreview" + channel.name);
      console.log("canvasName", "scanImagePreview" + channel.name);
      canvas.width = previewWidth;
      canvas.height = previewHeight;
      const ctx = canvas.getContext("2d");
      const offsetX = previewWidth * (index % 3);
      const offsetY = previewHeight * Math.floor(index / 3);
      ctx.drawImage(
        img,
        offsetX,
        offsetY,
        previewWidth,
        previewHeight,
        0,
        0,
        previewWidth,
        previewHeight
      );
      drawSaturatedPixelMask(img, 225, ctx);
      index++;
    }
  };
}

function getColor(row, col, value) {
  let color = tinycolor("#ffffff");
  switch (row) {
    case 1:
      color = tinycolor("#ff0000");
      break;
    case 2:
      color = tinycolor("#00ff00");
      break;
    case 3:
      color = tinycolor("#0000ff");
      break;
  }
  const darknessFactor = 30 * (1 - value / 255);
  // console.log(
  //   value,
  //   darknessFactor,
  //   color.toHexString(),
  //   color.darken(darknessFactor).toHexString()
  // );
  return color.darken(darknessFactor).toHexString();
}
</script>

<style>
#scanImagePreview {
  width: 100%;
  height: 100%;
  background-color: #000;
}
#scanImage {
  display: none;
}
.ImagePreview {
  display: flex;
  justify-content: center;
}
.dataEntry {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.swiper-pagination-bullet{ 
  padding:8px !important; 
}

.swiper-pagination-horizontal{
  display:flex !important;
  justify-content: space-evenly !important;
  height:15px !important;
}


</style>
