<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title class="fieldspec-card-title">
        FieldSpec
        <ion-spinner v-if="isConnecting" />
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <p>
              <ion-label>Device name: </ion-label>
              <ion-label>{{ deviceInfo.name }}</ion-label>
            </p>
            <p>
              <ion-label>Device version: </ion-label>
              <ion-label>{{ deviceInfo.version }}</ion-label>
            </p>
            <p>
              <ion-label>Device channels: </ion-label>
              <ion-label>{{ JSON.stringify(deviceInfo.channels) }}</ion-label>
            </p>
            <p>
              <ion-label>Device battery level: </ion-label>
              <ion-label>{{ deviceInfo.batteryLevel }}</ion-label>
            </p>
            <!-- <p>
              <ion-label>DEBUG </ion-label>
              <ion-label>{{ JSON.stringify(deviceInfo) }}</ion-label>
            </p>
            <p>
              <ion-label>DEBUG </ion-label>
              <ion-label>{{ JSON.stringify(deviceState) }}</ion-label>
            </p> -->
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p>Device State: {{ deviceStateToString(deviceState.state) }}</p>
            <p>
              Shutter:
              {{
                deviceState.shutter === 0x01
                  ? "open"
                  : deviceState.shutter === 0x02
                  ? "closed"
                  : "unknown"
              }}
            </p>
            <p>
              Trigger:
              {{
                deviceState.trigger === 0x01
                  ? "triggered"
                  : deviceState.trigger === 0x02
                  ? "triggered and waiting"
                  : deviceState.trigger === 0x03
                  ? "triggered and waiting for reset"
                  : "unset"
              }}
            </p>
          </ion-col></ion-row
        >
      </ion-grid>

      <ion-buttons v-if="isConnected">
        <ion-button @click="initialize"> initialize </ion-button>
        <ion-button v-if="deviceState.shutter !== 1" @click="openShutter">
          open shutter
        </ion-button>
        <ion-button v-else @click="closeShutter"> close shutter </ion-button>
        <ion-button @click="optimize"> optimize </ion-button>
        <ion-button @click="measure"> measure </ion-button>
        <ion-button @click="measureCont"> measure cont. </ion-button>
      </ion-buttons>

      <ion-buttons v-if="isConnected">
        <ion-button @click="setIntegrationTime"> set int </ion-button>
        <ion-button @click="resetTrigger"> resetTrigger </ion-button>
      </ion-buttons>

      <ion-buttons v-if="isConnected">
        <ion-button @click="recordDarkCurrent"> Record Darkcurrent </ion-button>
        <ion-button @click="recordWhiteBalance" :disabled="!darkCurrent">
          Record Whitebalance
        </ion-button>
      </ion-buttons>
    </ion-card-content>
    <ion-button 
      fill="clear" 
      v-if="!isConnected" 
      @click="connectToDevice"
      >
       Connect
      </ion-button
    >
    <ion-button 
      fill="clear" 
      v-if="isConnected" 
      @click="disconnectFromDevice"
    >
     Disconnect
     </ion-button
    >
  </ion-card>
</template>

<script setup>
import { IonSpinner } from "@ionic/vue";
import { ref } from "vue";
import { BleClient, numberToUUID } from "@capacitor-community/bluetooth-le";
import bleUtils from "../utils/bleUtils";
import { events } from "@/utils/events";
import { dataMessage } from "@/messages/dataMessage";

const BLE_UUIDS = {
  deviceControl: {
    service: "5ad224d6-6abd-4deb-ae20-c1c1d8f084e6",
    characteristics: {
      command: "96cfb92d-8bd7-4321-9082-9b7973acd614",
    },
  },
  deviceInfo: {
    service: "86417ce6-c0d8-47cd-8ffe-d905401e45c0",
    characteristics: {
      config: "aaf066c0-df5c-4ffc-8b7d-6cd57144908f",
      version: "98ecee82-fb2b-40fd-9aaa-98d3d1f38e16",
      channels: "54b57217-c654-4e8d-a405-21557ba06eb2",
      optimizeData: "313090a1-4a1a-4eff-a0c1-f13401a21f62",
      integrationTime: "3a2c83ba-723f-4d50-9efc-0b63c99474c7",
    },
  },
  deviceState: {
    service: "ca8fd6fd-4fde-45e8-a243-8578de8b2b43",
    characteristics: {
      state: "b3587fa6-abe2-4103-95f6-0041136a5a2e",
      shutter: "fcaa5a3c-4ecc-43c0-ba33-b6cfb419daee",
      trigger: "939177fe-73bc-407b-8c62-fbae51dc809d",
    },
  },
  measurement: {
    service: "9f8dfcc3-690e-46b8-b985-1e3fcade7aa7",
    characteristics: {
      data: "80a3e31d-0aa2-4c14-8e70-e71f148c2f1a",
    },
  },
  battery: {
    service: numberToUUID(0x180f),
    characteristics: {
      level: numberToUUID(0x2a19),
    },
  },
};
BLE_UUIDS.allServices = [
  BLE_UUIDS.deviceControl.service,
  BLE_UUIDS.deviceInfo.service,
  BLE_UUIDS.deviceState.service,
  BLE_UUIDS.measurement.service,
  BLE_UUIDS.battery.service,
];

const BLE_REQUEST_OPTIONS = {
  // Measurement service is the only service advertised
  // by the bridge device. So we can use it to find the device.
  services: [BLE_UUIDS.measurement.service],
  optionalServices: BLE_UUIDS.allServices,
};

const isConnected = ref(false);
const isConnecting = ref(false);
const deviceInfo = ref({
  name: undefined,
  version: undefined,
  channels: undefined,
  batteryLevel: undefined,
});

const deviceState = ref({
  state: undefined,
  shutter: undefined,
  trigger: undefined,
});

const inputCommand = ref("");
const bleDeviceId = ref(null);
const measureContMode = ref(false);
const whiteBalance = ref(undefined);
const darkCurrent = ref(undefined);
const darkCurrentRequested = ref(false);
const whiteBalanceRequested = ref(false);
const NUMBER_OF_CHANNELS = 2151;
let measurementDataBuffer = [];

function deviceStateToString(deviceState) {
  switch (deviceState) {
    case 0x00:
      return "init";
    case 0x01:
      return "boot";
    case 0x02:
      return "connecting";
    case 0x03:
      return "idle";
    case 0x04:
      return "busy";
    case 0x10:
      return "measurement";
    case 0xfa:
      return "communitcation timeout";
    case 0xfb:
      return "protocol error, wrong welcome";
    case 0xfc:
      return "no ethernet hardware";
    case 0xfd:
      return "no connection";
    case 0xfe:
      return "no ethernet cable";
    case 0xff:
      return "unknown error";
  }
}

async function connectToDevice() {
  console.log("connectToDevice");
  if (isConnected.value) {
    console.log("Already connected to device");
    return;
  }
  isConnecting.value = true;
  try {
    bleDeviceId.value = undefined;
    console.log("Connecting to device", BLE_REQUEST_OPTIONS);
    const deviceId = await BleClient.requestDevice(BLE_REQUEST_OPTIONS);
    if (!deviceId) {
      throw new Error("No device found");
    }
    bleDeviceId.value = deviceId.deviceId;

    console.log("Device found", bleDeviceId.value);
    await BleClient.connect(bleDeviceId.value);
    console.log(await BleClient.getServices(bleDeviceId.value));
    isConnected.value = true;
    deviceInfo.value.name = deviceId.name;
    await readVersion();
    await readChannels();
    await subscribeToDeviceData();
    await readBatteryLevel();
  } catch (error) {
    console.error("Error connecting to device", error);
  }
  isConnecting.value = false;
}

async function readVersion() {
  console.log("readVersion");
  if (!isConnected.value) {
    console.log("Not connected to device");
    return;
  }
  const version = await BleClient.read(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.version
  );
  console.log("Version", version);
  deviceInfo.value.version = bleUtils.toString(version);
  console.log(deviceInfo.value);
}

async function readChannels() {
  console.log("readChannels");
  if (!isConnected.value) {
    console.log("Not connected to device");
    return;
  }
  const channels = await BleClient.read(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.channels
  );
  console.log("Channels", channels);
  deviceInfo.value.channels = JSON.parse(bleUtils.toString(channels));
  console.log(deviceInfo.value);
}

async function readBatteryLevel() {
  console.log("readBatteryLevel");
  if (!isConnected.value) {
    console.log("Not connected to device");
    return;
  }
  const batteryLevel = await BleClient.read(
    bleDeviceId.value,
    BLE_UUIDS.battery.service,
    BLE_UUIDS.battery.characteristics.level
  );
  console.log("Battery level", batteryLevel);
  deviceInfo.value.batteryLevel = batteryLevel.getUint8(0);
  console.log(deviceInfo.value);
}

async function subscribeToDeviceData() {
  console.log("subscribeToDeviceData");
  if (!isConnected.value) {
    console.log("Not connected to device");
    return;
  }
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.config,
    onConfigData
  );

  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.optimizeData,
    onOptimizeData
  );

  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.integrationTime,
    onIntegrationTimeData
  );
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.state,
    onDeviceStateChange
  );
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.shutter,
    onShutterStateChange
  );
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.trigger,
    onTriggerStateChange
  );
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.measurement.service,
    BLE_UUIDS.measurement.characteristics.data,
    onMeasurementData
  );
  await BleClient.startNotifications(
    bleDeviceId.value,
    BLE_UUIDS.battery.service,
    BLE_UUIDS.battery.characteristics.level,
    onBatteryLevelChange
  );
}

async function unsubscribeFromDeviceData() {
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.config
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.optimizeData
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceInfo.service,
    BLE_UUIDS.deviceInfo.characteristics.integrationTime
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.state
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.shutter
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.deviceState.service,
    BLE_UUIDS.deviceState.characteristics.trigger
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.measurement.service,
    BLE_UUIDS.measurement.characteristics.data
  );
  await BleClient.stopNotifications(
    bleDeviceId.value,
    BLE_UUIDS.battery.service,
    BLE_UUIDS.battery.characteristics.level
  );
}

function onConfigData(value) {
  console.log("onConfigData", value);
}

function onDeviceStateChange(value) {
  console.log("onDeviceStateChange", value);
  deviceState.value.state = value.getUint8(0);
}

function onTriggerStateChange(value) {
  console.log("onTriggerStateChange", value.getUint8(0));
  if (value.getUint8(0) !== 0) {
    console.log("TRIGGER");
    resetTrigger();
  }
  deviceState.value.trigger = value.getUint8(0);
}

function onShutterStateChange(value) {
  console.log("onShutterStateChange", value);
  deviceState.value.shutter = value.getUint8(0);
}

function applyDarkCurrentToMeasurementData(measurementData) {
  if (isDarkCurrentAvailable()) {
    return measurementData.map((value, index) => {
      const newValue = value - darkCurrent.value[index];
      return newValue > 0 ? newValue : 0;
    });
  }
  return measurementData;
}

function applyWhiteBalanceToMeasurementData(measurementData) {
  if (isWhiteBalanceAvailable()) {
    return measurementData.map((value, index) => {
      return value / whiteBalance.value[index];
    });
  }
  return measurementData;
}

// function resetWhiteBalance() {
//   whiteBalance.value = undefined;
// }

// function resetDarkCurrent() {
//   darkCurrent.value = undefined;
// }

function isDarkCurrentAvailable() {
  return (
    darkCurrent.value !== undefined &&
    darkCurrent.value.length === NUMBER_OF_CHANNELS
  );
}

function isWhiteBalanceAvailable() {
  return (
    whiteBalance.value !== undefined &&
    whiteBalance.value.length === NUMBER_OF_CHANNELS
  );
}

async function recordDarkCurrent() {
  darkCurrentRequested.value = true;
  if (deviceState.value.shutter !== 1) {
    await closeShutter();
  }
  if (measureContMode.value === false) {
    // Only trigger a new measurement if we are not in continuous mode
    // if in continuous mode the next measurement will be recorded
    measure();
  }
}

function recordWhiteBalance() {
  if (!isDarkCurrentAvailable()) {
    console.warn("Dark current has to be recorded first");
    return;
  }
  whiteBalanceRequested.value = true;
  if (measureContMode.value === false) {
    // Same condition as in recordDarkCurrent().
    measure();
  }
}

function resetMeasurementDataBuffer() {
  measurementDataBuffer = [];
}

function appendToMeasurementDataBuffer(newData) {
  if (!measurementDataBuffer) {
    // Start a new buffer if none exists
    console.log("Start new buffer");
    measurementDataBuffer = new Uint8Array(newData.buffer);
  } else {
    // Append to existing buffer
    measurementDataBuffer = new Uint8Array([
      ...measurementDataBuffer,
      ...new Uint8Array(newData.buffer),
    ]);
    console.log("append to buffer", measurementDataBuffer.length);
  }
}

function isMeasurementDataBufferComplete() {
  return measurementDataBuffer.length === NUMBER_OF_CHANNELS * 4;
}

function onMeasurementData(value) {
  // console.log("onMeasurementData", value);
  console.log(value, value.buffer);

  appendToMeasurementDataBuffer(value);
  console.log("buffer length", measurementDataBuffer.length);

  if (isMeasurementDataBufferComplete()) {
    const sensorData = dataBufferToFloat32Array(measurementDataBuffer);
    if (darkCurrentRequested.value === true) {
      darkCurrent.value = sensorData;
      // Nullify darkcurrent from index 651 to 2151
      for (let i = 651; i < NUMBER_OF_CHANNELS; i++) {
        darkCurrent.value[i] = 0;
      }
      darkCurrentRequested.value = false;
    }

    if (whiteBalanceRequested.value === true) {
      // Whitebalance can only be recorded if dark current has been recorded before
      if (isDarkCurrentAvailable()) {
        whiteBalance.value = applyDarkCurrentToMeasurementData(sensorData);
      }
      whiteBalanceRequested.value = false;
    }

    const data = dataMessage();
    data.source = "FieldSpecModule";
    data.shape = [1, NUMBER_OF_CHANNELS];
    data.config = deviceState.value;
    data.config.darkCurrent = darkCurrent.value;
    data.config.whiteBalance = whiteBalance.value;

    let values = sensorData;
    data.type = "raw";
    if (isDarkCurrentAvailable()) {
      values = applyDarkCurrentToMeasurementData(values);
      data.type = "darkCurrentApplied";
    }
    if (isWhiteBalanceAvailable()) {
      values = applyWhiteBalanceToMeasurementData(values);
      data.type = "whiteBalanceApplied";
    }
    data.values = [values];

    events.emit("data", data);

    resetMeasurementDataBuffer();
    // If in continuous mode trigger a new mesaurement
    if (measureContMode.value === true) {
      setTimeout(measure, 10);
    }
  }
}

function onOptimizeData(value) {
  console.log("onOptimizeData", value);
  if (!deviceState.value.optimizeData) {
    deviceState.value.optimizeData = {};
  }
  const dataView = new DataView(value.buffer);
  deviceState.value.optimizeData.itime = dataView.getInt32(0, false);
  deviceState.value.optimizeData.gain1 = dataView.getInt32(4, false);
  deviceState.value.optimizeData.gain2 = dataView.getInt32(8, false);
  deviceState.value.optimizeData.offset1 = dataView.getInt32(12, false);
  deviceState.value.optimizeData.offset2 = dataView.getInt32(16, false);
  console.log(deviceState.value.optimizeData);
}

function onIntegrationTimeData(value) {
  console.log("onIntegrationTimeData", value);
  const dataView = new DataView(value.buffer);
  deviceState.value.integrationTime = dataView.getInt32(0, false);
  console.log(deviceState.value.integrationTime);
}

function dataBufferToFloat32Array(dataBuffer) {
  const SIZE_OF_FLOAT32 = 4;
  const data = [];
  let offset = 0;
  const isLittleEndian = false;
  const dataView = new DataView(dataBuffer.buffer);
  while (offset < dataBuffer.length) {
    data.push(dataView.getFloat32(offset, isLittleEndian));
    offset += SIZE_OF_FLOAT32;
  }
  return data;
}

function onBatteryLevelChange(value) {
  console.log("onBatteryLevelChange", value);
  deviceInfo.value.batteryLevel = value.getUint8(0);
}

async function disconnectFromDevice() {
  console.log("disconnectFromDevice");
  await unsubscribeFromDeviceData();
  await BleClient.disconnect(bleDeviceId.value);
  bleDeviceId.value = undefined;
  isConnected.value = false;
}

async function openShutter() {
  await sendCommand({ action: "openShutter" });
}

async function closeShutter() {
  await sendCommand({ action: "closeShutter" });
}

async function optimize() {
  await sendCommand({ action: "optimize" });
}
async function initialize() {
  await sendCommand({ action: "initialize" });
}

async function measure() {
  measurementDataBuffer = undefined;
  await sendCommand({ action: "measure" });
}

async function resetTrigger() {
  measurementDataBuffer = undefined;
  await sendCommand({ action: "resetTrigger" });
}

let i = 0;
async function setIntegrationTime(integrationTime) {
  i++;
  if (i >= 15) {
    i = 0;
  }
  console.log(i);
  await sendCommand({
    action: "setIntegrationTime",
    integration_time_index: i,
  });
}

async function measureCont() {
  if (measureContMode.value === true) {
    measureContMode.value = false;
  } else {
    measureContMode.value = true;
    await measure();
  }
}

async function sendCommand(command) {
  console.log("sendCommand", inputCommand.value);
  // // const command = inputCommand.value;
  // const command = JSON.stringify({
  //   action: "setConfig",
  //   test: "test",
  //   alot: "of data in this particular command",
  //   add: "another thing",
  //   with: 5,
  //   and: true,
  //   array: [1, 2, 3, 4, 5],
  // });
  if (!command) {
    return;
  }

  const commandString = JSON.stringify(command);
  console.log("sendCommand", commandString);
  const MAX_COMMAND_LENGTH = 64;
  const TOTAL_LENGTH = commandString.length;
  let offset = 0;
  while (offset < TOTAL_LENGTH) {
    const chunk = commandString.substring(offset, offset + MAX_COMMAND_LENGTH);
    const data = new TextEncoder("utf8").encode(chunk);
    // console.log(data);
    BleClient.write(
      bleDeviceId.value,
      BLE_UUIDS.deviceControl.service,
      BLE_UUIDS.deviceControl.characteristics.command,
      data
    );
    offset += chunk.length;
  }
  // bleUtils.sendCommand(bleDeviceId.value, command);
}
</script>

<style>
.fieldspec-card-title {
  display: flex;
  justify-content: space-between;
}
</style>
